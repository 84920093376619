@if (!orders) {
<app-spinner></app-spinner>
}
<div class="inner-page-header"><h3>الطلبات</h3></div>
@if (orders) {
<div class="main-content">
  <!-- buttons -->
  <div class="order-btn">
    <div class="instruction d-flex w-100">
      <button class="btn btn-add" mat-button [matMenuTriggerFor]="menu">
        تنفيذ أمر
        <img src="assets/images/svg/Polygon-1.svg" alt="" />
      </button>
      <button class="btn btn-yellow" (click)="duplicatOrder(orderId)">إعادة فاحصين</button>
    </div>
    <mat-menu #menu="matMenu">
      @if ( (order_status == orderStatus.Completed || order_status ==
      orderStatus.Delivered) && !qcReviewExist && !disableQC ) {
      <a mat-menu-item (click)="openSurvey()">إضافة تقييم جودة الخدمة</a>
      }
      <a mat-menu-item (click)="updateData()">إرسال تحديث البيانات</a>
      <a mat-menu-item (click)="requestReinspection()"> إنشاء طلب إعادة فحص </a>
      <a mat-menu-item (click)="notifyInspectorsWithOrder()"
        >تنبيه الفاحصين بالطلب مرة أخرى</a
      >
    </mat-menu>
  </div>
  <div class="row">
    <div class="col-md-12">
      @if (alertmsg) {
      <div class="alert-box">
        <img
          alt=""
          src="assets/images/svg/error-icon.svg"
          class="close-alert"
          (click)="hideErrorMSG()"
          alt=""
        />
        <p class="alert-msg">{{ errorMessage }}</p>
      </div>
      }
    </div>
  </div>
  <app-order-info (errorMsg)="showMSG($event)"></app-order-info>
  <!----------------------------------accordions--------------------------------------->
  <form
    class="needs-validation"
    novalidate
    #form="ngForm"
    id="general-form"
    [formGroup]="orderDetailsFormGroup"
  >
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.clientNotes = true"
        (closed)="panelOpen.clientNotes = false"
        [ngClass]="{
          'closed-panel': panelOpen.clientNotes == false,
          'open-panel': panelOpen.clientNotes == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> مناقشة الطلب</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-client-notes></app-client-notes>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Refund -->
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.refund = true"
        (closed)="panelOpen.refund = false"
        [ngClass]="{
          'closed-panel': panelOpen.refund == false,
          'open-panel': panelOpen.refund == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> المعاملات المالية </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          @if (orders) {
          <div>
            @if (orders.is_paid) {
            <app-refund-view>
            </app-refund-view>
            } @else {
            <div class="purchase">
              <button
                class="btn btn-yellow btn-payment"
                [routerLink]="['purchase']"
              >
                دفع يدوي (بنكي)
              </button>
            </div>
            }
            <ng-template #purchase>
              <div class="purchase">
                <button
                  class="btn btn-yellow btn-payment"
                  [routerLink]="['purchase']"
                >
                  دفع يدوي (بنكي)
                </button>
              </div>
            </ng-template>
          </div>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- additional-amount-view -->
    @if (orderType != orderTypeEnum.Visit && orders.sub_order.length > 0) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.additionalServices = true"
        (closed)="panelOpen.additionalServices = false"
        [ngClass]="{
          'closed-panel': panelOpen.additionalServices == false,
          'open-panel': panelOpen.additionalServices == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> الخدمات الإضافية </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          @if (orders) {
          <div>
            <app-additional-view > </app-additional-view>
          </div>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!-- /additional-amount-->
    <!-- main details-->
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.mainDetails = true"
        (closed)="panelOpen.mainDetails = false"
        [ngClass]="{
          'closed-panel': panelOpen.mainDetails == false,
          'open-panel': panelOpen.mainDetails == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title>تفاصيل الطلب</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          @if (orders) {
          <order-main-details (errorMsg)="showMSG($event)"></order-main-details>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- report-->
    @if ( order_status == orderStatus.Under_Audit || order_status ==
    orderStatus.Delivered || order_status == orderStatus.Completed ) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.report = true"
        (closed)="panelOpen.report = false"
        [ngClass]="{
          'closed-panel': panelOpen.report == false,
          'open-panel': panelOpen.report == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> التقرير</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-order-report
            (errorMsg)="showMSG($event)"
            (save)="saveReportEdit($event)"
            (notes)="applyEdit($event)"
          >
          </app-order-report>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!-- Reating-->
    @if ( orderType != orderTypeEnum.Visit && (order_status ==
    orderStatus.Completed || order_status == orderStatus.Delivered) &&
    (qcReviewExist || customerReviewExist) ) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.rate = true"
        (closed)="panelOpen.rate = false"
        [ngClass]="{
          'closed-panel': panelOpen.rate == false,
          'open-panel': panelOpen.rate == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> التقييم </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <rating-view
            [orderID]="orderId"
            [ratingData]="ratingData"
            [qcReviewExist]="qcReviewExist"
            [customerReview]="customerReview"
            [customerReviewExist]="customerReviewExist"
            [qcReview]="qcReview"
            [disableQC]="disableQC"
            [disableCustomer]="disableCustomer"
            (changeQCSurveyData)="changeQCSurveyData($event)"
          >
          </rating-view>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!-- order-details-->
    <!-- build details new component -->
    @if (orderType != orderTypeEnum.ReInspection) {
    <mat-accordion class="nopadding">
      <mat-expansion-panel
        (opened)="panelOpen.buildingDetails = true"
        (closed)="panelOpen.buildingDetails = false"
        [ngClass]="{
          'closed-panel': panelOpen.buildingDetails == false,
          'open-panel': panelOpen.buildingDetails == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> تفاصيل العقار </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body nopadding child-panel">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="childPanelOpenState = true"
              (closed)="childPanelOpenState = false"
              [ngClass]="{
                'closed-panel': childPanelOpenState == false,
                'open-panel': childPanelOpenState == true
              }"
            >
              <mat-expansion-panel-header class="header-child">
                <mat-panel-title> موقع العقار </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-body">
                @if (orders) {
                <property-location
                  (errorMsg)="showMSG($event)"
                  [orderID]="orderId"
                >
                </property-location>
                }
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="childPanelOpenState = true"
              (closed)="childPanelOpenState = false"
              [ngClass]="{
                'closed-panel': childPanelOpenState == false,
                'open-panel': childPanelOpenState == true
              }"
            >
              <mat-expansion-panel-header class="header-child">
                <mat-panel-title> بيانات العقار </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-body">
                @if (orders) {
                <building-details
                  [orderId]="orderId"
                  (errorMsg)="showMSG($event)"
                  [orderType]="orders?.order_type"
                  [orderData]="orders?.building_data"
                ></building-details>
                }
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="childPanelOpenState = true"
              (closed)="childPanelOpenState = false"
              [ngClass]="{
                'closed-panel': childPanelOpenState == false,
                'open-panel': childPanelOpenState == true
              }"
            >
              <mat-expansion-panel-header class="header-child">
                <mat-panel-title> مواصفات العقار </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-body">
                @if (orders) {
                <building-specifications
                  [orderID]="orderId"
                  [order_type]="orderType"
                  (errorMsg)="showMSG($event)"
                  [orderData]="orders?.building_specification"
                >
                </building-specifications>
                }
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          @if (orderType != orderTypeEnum.Visit) {
          <mat-accordion>
            <mat-expansion-panel
              (opened)="childPanelOpenState = true"
              (closed)="childPanelOpenState = false"
              [ngClass]="{
                'closed-panel': childPanelOpenState == false,
                'open-panel': childPanelOpenState == true
              }"
            >
              <mat-expansion-panel-header class="header-child">
                <mat-panel-title>وثائق العقار</mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-body">
                @if (orders) {
                <order-files (errorMsg)="showMSG($event)"></order-files>
                }
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          } @if (orderType != orderTypeEnum.Visit) {
          <mat-accordion>
            <mat-expansion-panel
              (opened)="childPanelOpenState = true"
              (closed)="childPanelOpenState = false"
              [ngClass]="{
                'closed-panel': childPanelOpenState == false,
                'open-panel': childPanelOpenState == true
              }"
            >
              <mat-expansion-panel-header class="header-child">
                <mat-panel-title> مميزات العقار الأخرى</mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-body">
                @if (orders) {
                <other-features
                  [orderID]="orderId"
                  (errorMsg)="showMSG($event)"
                  [orderData]="orders"
                ></other-features>
                }
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!-- order additions  component -->
    @if (orders.order_additions.length > 0) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.clientNotes = true"
        (closed)="panelOpen.clientNotes = false"
        [ngClass]="{
          'closed-panel': panelOpen.clientNotes == false,
          'open-panel': panelOpen.clientNotes == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> إضافات الطلب</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <order-additions
            [additions]="orders.order_additions"
            [orderId]="orderId"
            (errorMsg)="showMSG($event)"
          >
          </order-additions>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!--infrastructure and services-->
    @if ( !( orderType == orderTypeEnum.Visit || orderType ==
    orderTypeEnum.ReInspection ) ) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.infrastructure = true"
        (closed)="panelOpen.infrastructure = false"
        [ngClass]="{
          'closed-panel': panelOpen.infrastructure == false,
          'open-panel': panelOpen.infrastructure == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> البنى التحتية والخدمات</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <infrastructure [orderID]="orderId" (errorMsg)="showMSG($event)">
          </infrastructure>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!--end infrastructure and services-->
    <!--Notes And Recommendations-->
    @if ( !( orderType == orderTypeEnum.Visit || orderType ==
    orderTypeEnum.ReInspection ) ) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.notes = true"
        (closed)="panelOpen.notes = false"
        [ngClass]="{
          'closed-panel': panelOpen.notes == false,
          'open-panel': panelOpen.notes == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> أبرز الملاحظات والتوصيات</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <notes-recommendations
            [orderID]="orderId"
            (errorMsg)="showMSG($event)"
          >
          </notes-recommendations>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!--end Notes And Recommendations-->
    <!-- report-->
    @if (orderType == orderTypeEnum.Visit) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.report = true"
        (closed)="panelOpen.report = false"
        [ngClass]="{
          'closed-panel': panelOpen.report == false,
          'open-panel': panelOpen.report == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> تفاصيل الزيارة</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <visit-summary></visit-summary>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!-- inspection log -->
    @if (orderType != orderTypeEnum.Visit) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.inspectionLog = true"
        (closed)="panelOpen.inspectionLog = false"
        [ngClass]="{
          'closed-panel': panelOpen.inspectionLog == false,
          'open-panel': panelOpen.inspectionLog == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> سجل عملية الفحص</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-order-inspecting-log
            [orderData]="orders"
          ></app-order-inspecting-log>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    }
    <!-- order log -->
    @if (orderType != orderTypeEnum.Visit) {
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpen.orderLog = true"
        (closed)="panelOpen.orderLog = false"
        [ngClass]="{
          'closed-panel': panelOpen.orderLog == false,
          'open-panel': panelOpen.orderLog == true
        }"
      >
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title> سجل التقارير المصدرة</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        @if (orders) {
        <div class="panel-body">
          <app-order-reports-log [orderData]="orders"></app-order-reports-log>
        </div>
        }
      </mat-expansion-panel>
    </mat-accordion>
    }
  </form>
  <div class="row">
    <div class="col-md-12 double-action">
      <button class="btn btn-cancel" type="button" (click)="goList()">
        رجوع
      </button>
    </div>
  </div>
</div>
}
