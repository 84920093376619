import { Component, Input, OnInit } from '@angular/core';
import { AdminOrdersService } from '../../../services/orders/admin-orders.service';
import { Select } from '@ngxs/store';
import { OrderState } from '../../../state/order.state';
import { Observable } from 'rxjs';
/**
 *  define and export AdditionalAmountViewComponent class
 */
@Component({
  selector: 'app-additional-view',
  templateUrl: './additional-amount-view.component.html',
  styleUrls: ['./additional-amount-view.component.scss'],
})
export class AdditionalAmountViewComponent  implements OnInit{
  orderData: any;
  received = {
    is_received: true,
  };
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;

  constructor(private order: AdminOrdersService) {}

  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orderData = data;
    });
  }

  deliveredOrder(id, received) {
    this.order.changePaymentStatus(id, received).subscribe(
      () => {
        this.orderData.sub_order.forEach((order) => {
          if (order.pk == id) {
            order.payment_status = 'مستلم';
          }
        });
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }
}
