import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NotFoundComponent } from '../features/not-found/not-found.component';
import { FeaturesComponent } from './features.component';
import { HomeComponent } from './home/home.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { CompaniesComponent } from './companies/companies.component';
import { FeaturesRoutingModule } from './features-routing.module';
import { OrdersModule } from './orders/orders.module';
import { CopounModule } from './copoun/copoun.module';
import { CustomersModule } from './customers/customers.module';
import { InspectorsModule } from './inspectors/inspectors.module';
import { SettingModule } from './setting/setting.module';
import { WhatsNewModule } from './whats-new/whats-new.module';
import { ProjectsModule } from './projects/projects.module';
import { SharedModule } from '../shared/shared.module';
import { SmsCampingsModule } from './sms-campings/sms-campings.module';
import { ServicesModule } from './ayen-services/services.module';
import { PricingComponent } from './pricing/pricing.component';
import { LogsComponent } from './logs/logs.component';

class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return [
      'الأحد',
      'الإثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
    ];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    FeaturesComponent,
    HomeComponent,
    NotFoundComponent,
    NotificationsComponent,
    ServerErrorComponent,
    CompaniesComponent,
    LogsComponent
  ],
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    OrdersModule,
    CopounModule,
    CustomersModule,
    SettingModule,
    WhatsNewModule,
    ProjectsModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    SmsCampingsModule,
    ServicesModule,
    InspectorsModule,
    
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-us' },
  ],
  exports: [FeaturesComponent],
})
export class FeaturesModule {}
