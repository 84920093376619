import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NewServicesState } from '../../state/ayen-service.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  GetAdditionsList,
  GetServiceById,
  addServiceSetting,
} from '../../state/service-action';
import { SettingServicesService } from '../../services/setting-services/setting-services.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromptColorBoxComponent } from 'src/app/shared/components/prompt-color-box/prompt-color-box.component';
import { PromptBoxComponent } from 'src/app/shared/components/prompt-box/prompt-box.component';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ListInputsDialogeComponent } from '../../components/list-inputs-dialoge/list-inputs-dialoge.component';
import { RecommendationDialogeComponent } from '../../components/recommendation-dialoge/recommendation-dialoge.component';

@Component({
  selector: 'services-settings',
  templateUrl: './services-settings.component.html',
  styleUrl: './services-settings.component.scss',
})
export class ServicesSettingsComponent implements OnInit {
  initAllowanceFields = [];
  allowanceFieldsValues = [];
  allowanceFields: any[];
  serviceId: any;
  @Select(NewServicesState.getServiceById) serviceById$: Observable<any>;
  @Select(NewServicesState.serverError) serverError$: Observable<string>;
  @Select(NewServicesState.getAdditionList) AdditionsList$: Observable<any>;

  serviceByIdSubscribtion: Subscription;
  serviceDetails: any;
  serviceSettingsFormGroup: FormGroup;
  addition_config: any;
  unit_config: any;
  floor_config: any;
  rooms_config: any;
  problems_config: any;
  recommendations_config: any;
  page: number = 1;
  totalRecords: any = 0;
  addition_payload: { page: any; query: string; page_size: any };
  unit_payload: { page: any; query: string; page_size: any };
  floor_payload: { page: any; query: string; page_size: any };
  rooms_payload: { page: any; query: string; page_size: any };
  problems_payload: { page: any; query: string; page_size: any };
  recommendations_payload: { page: any; query: string; page_size: any };

  additionList: any = [];
  dynamicServicesItems = new BehaviorSubject({});
  additionData = this.dynamicServicesItems.asObservable();
  roomsItems = new BehaviorSubject({});
  floorsItems = new BehaviorSubject({});
  recommendationItems = new BehaviorSubject({});
  unitItem = new BehaviorSubject({});
  // additionData = this.dynamicServicesItems.asObservable();
  initAdditions: any[] = [];
  currentItemsPerPage: any;
  addStatus: any;
  unitList: any[] = [];
  initUnits: any[] = [];
  initRooms: any[] = [];
  initFloors: any[] = [];
  initRecommendations: any[] = [];
  roomsList: any[] = [];
  floorsList: any[] = [];
  recommendationsList: any[] = [];
  problemsList: any;
  promptColorRef: MatDialogRef<PromptColorBoxComponent>;
  recommendationRef: MatDialogRef<RecommendationDialogeComponent>;
  listInputRef: MatDialogRef<ListInputsDialogeComponent>;
  promptRef: MatDialogRef<PromptBoxComponent>;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  successImg: boolean = true;
  confirmMsg: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private settingServices: SettingServicesService
  ) {}
  ngOnInit() {
    this.serviceId = this.activatedRoute.snapshot.params.id;
    this.setPaginationConfig('addition_config');
    this.setPaginationConfig('recommendations_config');
    this.setPaginationConfig('floor_config');
    this.setPaginationConfig('rooms_config');
    this.setPaginationConfig('unit_config');
    this.setPaginationConfig('problems_config');
    this.createForm();
    this.createFormControls();
    this.createAllowanceFieldsList();
    this.getServiceById();
    this.getDynamicAdditions();
    this.getUnitMesurment();
    this.getRooms();
    this.getFloors();
    this.getRecommendations();
    this.getTechnicalProblemsTypes();
  }
  ngOnDestroy(): void {
    this.serviceByIdSubscribtion?.unsubscribe();
    // window.removeEventListener('scroll', this.onScroll, true);
  }

  setPaginationConfig(config) {
    this[config] = {
      id: config,
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
      pageCount: 0,
    };
  }
  createForm() {
    this.serviceSettingsFormGroup = this.formBuilder.group({
      name: [''],
      allow_ratios_in_inspector_view: [false],
      allow_ratios_in_report_view: [false],
      allow_inspector_to_skip_terms: [false],
      is_droplist: [false],
      show_violation_total_price_field: [false],
      show_violation_count_field: [false],
      show_technical_problems_type: [[]],
      show_term_field: [true],
      show_description_field: [true],
      show_photos_field: [true],
      show_status_field: [true],
      show_notes_field: [true],
      show_room_field: [false],
      show_floor_field: [false],
      show_quantity_field: [false],
      show_units_of_measurement_field: [false],
      show_recommendation_field: [false],
      is_ai_enabled: [false],
      units_of_measurement: [[]],
      rooms: [[]],
      floors: [[]],
      recommendations: [[]],
      additions: [null],
    });
  }
  createFormControls() {
    return this.serviceSettingsFormGroup.controls;
  }
  createAllowanceFieldsList() {
    this.allowanceFields = [
      {
        id: 'allow_ratios_in_inspector_view',
        name: 'إظهار النسب المؤية للفاحص',
      },
      {
        id: 'allow_ratios_in_report_view',
        name: 'إظهار النسب المؤيه فى التقرير',
      },
      { id: 'show_violation_total_price_field', name: 'إظهار التكلفة' },
      { id: 'show_violation_count_field', name: 'إظهار العدد' },
    ];
  }

  isActiveValueChanged(event) {
    this.serviceSettingsFormGroup.get(event.labelEn).setValue(event.value);
  }

  getServiceById() {
    this.serviceByIdSubscribtion = this.serviceById$.subscribe(
      (data) => {
        if (data?.id && data?.id == +this.serviceId) {
          this.serviceDetails = data;
          this.setInitAllowanceFields();
          this.initAdditions = this.serviceDetails.additions;
          this.initUnits = this.serviceDetails.units_of_measurement;
          this.initRooms = this.serviceDetails.rooms;
          this.initFloors = this.serviceDetails.floors;
          this.initRecommendations = this.serviceDetails.recommendations;
          this.serviceSettingsFormGroup.patchValue({
            allow_inspector_to_skip_terms:
              this.serviceDetails.allow_inspector_to_skip_terms,
            is_droplist: this.serviceDetails.is_droplist,
            completion_status: this.serviceDetails.completion_status,
            building_data_to_show: this.serviceDetails.building_data_to_show,
            show_violation_total_price_field:
              this.serviceDetails.show_violation_total_price_field,
            show_technical_problems_type:
              this.serviceDetails.show_technical_problems_type,
            show_room_field: this.serviceDetails.show_room_field,
            show_violation_count_field:
              this.serviceDetails.show_violation_count_field,
            show_term_field: this.serviceDetails.show_term_field,
            show_description_field: this.serviceDetails.show_description_field,
            show_photos_field: this.serviceDetails.show_photos_field,
            show_status_field: this.serviceDetails.show_status_field,
            show_notes_field: this.serviceDetails.show_notes_field,
            show_floor_field: this.serviceDetails.show_floor_field,
            show_units_of_measurement_field:
              this.serviceDetails.show_units_of_measurement_field,
            show_quantity_field: this.serviceDetails.show_quantity_field,
            show_recommendation_field:
              this.serviceDetails.show_recommendation_field,
              is_ai_enabled: this.serviceDetails.is_ai_enabled
          });
        } else {
          this.store.dispatch(new GetServiceById(this.serviceId));
        }
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  setAllowanceFields(event) {
    this.allowanceFieldsValues = event;
  }

  getDynamicAdditions() {
    this.addition_payload = {
      page: this.addition_config.currentPage,
      query: '',
      page_size: this.addition_config.itemsPerPage,
    };
    this.AdditionsList$.subscribe(
      (data) => {
        this.addition_config.pageCount = data?.pages_count;
        if (
          data &&
          data['results'] &&
          data.page == this.addition_config.currentPage
        ) {
          if (
            !this.additionList.some((el) => el.id === data['results'][0].id)
          ) {
            this.additionList.push(...data.results);
            this.dynamicServicesItems.next(this.additionList);
          }
          this.addition_config['totalItems'] = data.count;
          this.currentItemsPerPage = data.results.length;
        } else {
          this.store.dispatch(new GetAdditionsList(this.addition_payload));
        }
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  setInitAllowanceFields() {
    let allowanceFiellds = [];
    this.allowanceFields.forEach((field) => {
      if (this.serviceDetails[field.id]) {
        allowanceFiellds.push(field.id);
      }
    });
    this.initAllowanceFields = allowanceFiellds;
  }

  onScrollOptions(event, field, fnName) {
    if (
      event &&
      this[field + '_payload'].page < this[field + '_config'].pageCount
    ) {
      this[field + '_payload'].page++;
      this[field + '_config'].currentPage = this[field + '_payload'].page;
    }
    this[fnName]();
  }
  setDropMenus(event, controlName) {
    this.serviceSettingsFormGroup.controls[controlName].patchValue([...event]);
  }

  save() {
    this.serviceSettingsFormGroup.controls['name'].setValue(
      this.serviceDetails.name
    );
    this.allowanceFields.forEach((field) => {
      this.serviceSettingsFormGroup.controls[field.id].setValue(
        this.allowanceFieldsValues.includes(field.id) ? true : false
      );
    });
    if (!this.addStatus) {
      this.store
        .dispatch(
          new addServiceSetting(
            this.serviceId,
            this.serviceSettingsFormGroup.value
          )
        )
        .subscribe(
          (data) => {
            this.successImg = true;
            this.confirmMsg = 'تم الحفظ بنجاح';
            this.showAlertBox();
          },
          (error) => {
            try {
              this.successImg = false;
              this.confirmMsg = error.error;
              this.showAlertBox();
            } catch (error) {}
          }
        );
    }
  }

  getUnitMesurment() {
    this.unit_payload = {
      page: this.unit_config.currentPage,
      query: '',
      page_size: this.unit_config.itemsPerPage,
    };
    this.settingServices.getUnitOfMeasurements(this.unit_payload).subscribe((data) => {
      this.unitList = data.results;
      this.unitItem.next(this.unitList);
      this.unit_config['totalItems'] = data.count;
    });
  }

  getRooms() {
    this.rooms_payload = {
      page: this.rooms_config.currentPage,
      query: '',
      page_size: this.rooms_config.itemsPerPage,
    };
    this.settingServices.getRooms(this.rooms_payload).subscribe((data) => {
      this.rooms_config.pageCount = data?.pages_count;
      if (
        !this.roomsList.some((el) => el.id === data['results'][0].id)
      ) {
        this.roomsList.push(...data.results);
        this.roomsItems.next(this.roomsList);
      }
      this.rooms_config['totalItems'] = data.count;
    });
  }

  getFloors() {
    this.floor_payload = {
      page: this.floor_config.currentPage,
      query: '',
      page_size: this.floor_config.itemsPerPage,
    };
    this.settingServices.getFloors(this.floor_payload).subscribe((data) => {
      this.floor_config.pageCount = data?.pages_count;
      if (
        !this.floorsList.some((el) => el.id === data['results'][0].id)
      ) {
        this.floorsList.push(...data.results);
        this.floorsItems.next(this.floorsList);
      }
      this.floor_config['totalItems'] = data.count;
    });
  }

  getRecommendations() {
    this.recommendations_payload = {
      page: this.recommendations_config.currentPage,
      query: '',
      page_size: this.recommendations_config.itemsPerPage,
    };
    this.settingServices.getRecommendations(this.recommendations_payload).subscribe((data) => {
      this.recommendations_config.pageCount = data?.pages_count;
      if (
        !this.recommendationsList.some((el) => el.id === data['results'][0].id)
      ) {
        this.recommendationsList.push(...data.results);
        this.recommendationItems.next(this.recommendationsList);
      }
      this.recommendations_config['totalItems'] = data.count;
    });
  }

  getTechnicalProblemsTypes() {
    this.settingServices.getTechnicalProblems().subscribe((data) => {
      this.problemsList = data;
    });
  }

  addRoom() {
    this.promptRef = this.dialog.open(PromptBoxComponent, {
      data: {
        label: 'اسم الغرفة',
        placeholder: 'برجاء ادخال الغرفة',
        textValue: '',
      },
    });
    this.promptRef.afterClosed().subscribe((results) => {
      if (results?.value) {
        this.settingServices
          .addRoom({ name: results.value })
          .subscribe((data) => {
            this.rooms_payload.page = 1;
            this.rooms_config.currentPage = this.rooms_payload.page;
            this.getRooms();
          });
      }
    });
  }

  addFloors() {
    this.promptRef = this.dialog.open(PromptBoxComponent, {
      data: {
        label: 'اسم الأدوار',
        placeholder: 'برجاء ادخال الدور',
        textValue: '',
      },
    });
    this.promptRef.afterClosed().subscribe((results) => {
      if (results?.value) {
        this.settingServices
          .addFloor({ name: results.value })
          .subscribe((data) => {
            this.floor_payload.page = 1;
            this.floor_config.currentPage = this.floor_payload.page;
            this.getFloors();
          });
      }
    });
  }

  addUnitMeasurment() {
    this.promptRef = this.dialog.open(PromptBoxComponent, {
      data: {
        label: 'اسم وحدة القياس',
        placeholder: 'برجاء ادخال الوحدة',
        textValue: '',
      },
    });
    this.promptRef.afterClosed().subscribe((results) => {
      if (results?.value) {
        this.settingServices
          .addUnitOfMeasurements({ name: results.value })
          .subscribe((data) => {
            this.floor_payload.page = 1;
            this.floor_config.currentPage = this.floor_payload.page;
            this.getUnitMesurment();
          });
      }
    });
  }

  addRecommendation() {
    this.promptColorRef = this.dialog.open(PromptColorBoxComponent, {
      data: {
        textLabel: 'اسم التوصية',
        placeholder: 'برجاء ادخال التوصية',
        textValue: '',
        colorLabel: 'لون التوصية',
        colorValue: null,
        options: ['#40B58D', '#D9727F', '#FEB503'],
      },
    });
    this.promptColorRef.afterClosed().subscribe((results) => {
      if (results?.colorValue) {
        const data = {
          name: results.textValue,
          color: results.colorValue,
        };
        this.settingServices.addRecommendation(data).subscribe((data) => {
          this.recommendations_payload.page = 1;
          this.recommendations_config.currentPage =
            this.recommendations_payload.page;
          this.getRecommendations();
        });
      }
    });
  }

  goService() {
    this.router.navigate([`pages/services/edit/${this.serviceDetails.id}`]);
  }

  edit(data, getFn) {
    this.listInputRef = this.dialog.open(ListInputsDialogeComponent, {
      data: data,
    });
    this.listInputRef.afterClosed().subscribe((results) => {
      if (results) {
        this[getFn]();
      }
    });
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.successImg,
        message: this.confirmMsg,
        button: 'تم',
      },
    });
  }

  editRecommendation() {
    this.recommendationRef = this.dialog.open(RecommendationDialogeComponent, {
      data: {
        list: this.recommendationsList,
        options: ['#40B58D', '#D9727F', '#FEB503', '#E9CB71'],
        label: 'تعديل التوصية',
        fnName: 'updateRecommendation'
      },
    });
    this.recommendationRef.afterClosed().subscribe((results) => {
      if (results) {
       this.getRecommendations();
      }
    });
  }
}
