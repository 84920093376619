import { Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { FilterState } from 'src/app/core/state/filter-state/filter.state';

@Component({
  selector: 'saved-filters',
  templateUrl: './saved-filters.component.html',
  styleUrls: ['./saved-filters.component.scss']
})
export class SavedFiltersComponent implements OnInit {

  savedFilters = [];
  selectedFilterPk;
  @ViewChildren("item", { read: ElementRef }) filterItems: QueryList<ElementRef<HTMLParagraphElement>>;
  @Select(FilterState.getSavedFilters) savedFilters$: Observable<any>;
  @Select(FilterState.getSelectedFilter) selectedFilter$: Observable<any>;
  @Select(FilterState.getFilterParamsChanged) filterParamsChanged$: Observable<any>;
  @Input() page;

  constructor(private renderer: Renderer2,
    private filtersService: FiltersService) { }

  ngOnInit(): void {
    this.getSavedFilterList();
    this.setSelectedFilter();
    this.filterParamsChanged$.subscribe( data => {
      if(data) {
        this.removeSelectionActive();
      }
    })
  }

  getSavedFilterList() {
    this.savedFilters$.subscribe( data => {
      this.savedFilters = data;
    });
  }

  setSelectedFilter() {
    this.selectedFilter$.pipe(debounceTime(400)).subscribe( data => {
      const index = this.savedFilters?.findIndex( item => item.pk === data?.pk );
      if(index > -1) {
        this.selectFilter(data, index);
      }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  selectFilter(item, index) {
    this.selectedFilterPk = item.pk;
    let query = {};
    this.filterItems.forEach((elem, i) => {
      if(index === i && !elem.nativeElement.classList.contains('active')) {
        this.renderer.addClass(elem.nativeElement, 'active');
        query = {...item.query};
      } else {
        this.renderer.removeClass(elem.nativeElement, 'active');
      }
    });
    this.filtersService.changeSelectedFilter(query);
  }

  removeSelectionActive() {
    this.filterItems?.forEach((elem, i) =>{
      this.renderer.removeClass(elem.nativeElement, 'active');
    });
  }

  deleteSavedFilter(pk, index) {
    this.filtersService.deleteSavedFilter(pk).subscribe(data => {
      this.savedFilters.splice(index, 1);
      if(this.selectedFilterPk === pk) {
        this.filtersService.changeSelectedFilter({});
      }
    }, error => {
      try { }
      catch { }
    });
  }

 
}
