@if (spinner) {
<app-spinner></app-spinner>
}
<div class="inner-page-header">
  <h3>أكواد الخصم</h3>
</div>
@if (couponList) {
<div class="request-control">
  <div class="right-side">
    <div class="delete-icon-grid">
      <button
        class="btn btn-custom-delete"
        (click)="deleteSelectedRows()"
        [disabled]="disabledDelete"
      >
        <img src="assets/images/svg/new-delete.svg" alt="" />
      </button>
    </div>
    @if (filterData) {
    <app-filter
      [filterData]="filterData"
      [filterDataInit]="filterDataInit"
      [filterPage]="filterPage"
      (formValus)="filterFormValus($event)"
    >
    </app-filter>
    }
    <quick-search (searchResults)="quickSearch($event)"></quick-search>
  </div>  
  <div class="new-element">
    <button class="btn btn-add" (click)="addCoupon()">
      <img src="assets/images/svg/add-icon.svg" alt="" />
      إضافة كود خصم
    </button>
  </div>
</div>
}
<div class="saved-filters">
  <saved-filters></saved-filters>
</div>
@if (couponList && couponList.length > 0) {
<ayen-selected-count
  [itemsPerPage]="currentItemsPerPage"
  [selectedRows]="selectedRowsPK"
  [totalRowsSelected]="totalRowsSelected"
  [pageTitle]="pageTitle"
  [totalItems]="config.totalItems"
  (setTotalRowsSelected)="selectTotalRows($event)"
>
</ayen-selected-count>
}
<!-- coupon table -->
<div class="request-table">
  <div class="table-responsive">
    <table id="order-table" class="table table-striped" aria-label="">
      <thead>
        <tr>
          <th class="width-15" id="">
            <label class="checkbox-container">
              <input
                type="checkbox"
                id="custom_check_all"
                #selectAllElm
                (click)="selectAll($event.target)"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">كود الخصم</span>
          </th>
          <th class="width-10" id="">نوع الخصم</th>
          <th class="width-15" id="">قيمة الخصم</th>
          <th
            class="width-15"
            id=""
            (click)="toggleSrc('current_redeem_number', img1)"
          >
            مرات الإستخدام
            <img #img1 [src]="imgDefault" alt="" />
          </th>
          <th class="width-15" id="">نوع الخدمة</th>
          <th
            class="width-15"
            id=""
            (click)="toggleSrc('start_date_display', img2)"
          >
            تاريخ البدء
            <img #img2 [src]="imgDefault" alt="" />
          </th>
          <th
            class="width-15"
            id=""
            (click)="toggleSrc('end_date_display', img3)"
          >
            تاريخ الإنتهاء
            <img #img3 [src]="imgDefault" alt="" />
          </th>
          <th class="width-10" id="">حالة الكود</th>
        </tr>
      </thead>

      <tbody>
        @if (couponList) { @for (coupon of couponList | paginate: config ; track
        coupon; let i = $index) {
        <tr (click)="openCoupon(coupon.pk)">
          <td>
            <label class="checkbox-container" (click)="stopPropagation($event)">
              <input
                type="checkbox"
                #checkBoxList
                (click)="selectsSingleRow($event, coupon.pk, i)"
                [id]="i + '_' + coupon.pk"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">
              <a href="/pages/coupons/{{ coupon.pk }}">{{ coupon?.code }}</a>
            </span>
          </td>
          <td>{{ coupon?.discount_type_object?.name }}</td>
          <td>
            {{ coupon?.discount_value }} {{ coupon?.discount_value_display }}
          </td>
          <td>
            {{
              coupon.current_redeem_number ? coupon.current_redeem_number : "-"
            }}
          </td>
          <td>{{ coupon.services_display ? coupon.services_display : "-" }}</td>
          <td>{{ coupon?.start_date_display }}</td>
          <td>{{ coupon?.end_date_display }}</td>
          <td>
            <button
              class="btn-checked"
              [ngClass]="{
                'activ-coupon':
                  coupon.status_object.id === Coupon_status_enum.active,
                'inactive-coupon':
                  coupon.status_object.id === Coupon_status_enum.not_active,
                'finished-coupon':
                  coupon.status_object.id === Coupon_status_enum.finished
              }"
            >
              {{ coupon?.status_object?.name }}
            </button>
          </td>
        </tr>
        } @if (couponList.length === 0) {
        <tr>
          <td colspan="9" class="text-center">لا توجد بيانات</td>
        </tr>
        } }
      </tbody>
    </table>
  </div>
</div>

@if (couponList && couponList.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
