<ng-container *ngIf="places_categotris; else latLong">
    <div class="container">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of places_categotris">
                <div>
                    <p class="feature-title">{{item.name}}</p>
                    <table aria-labelledby="" class="table">
                        <thead><tr><th id=""></th><th id=""></th></tr></thead>
                        <tbody>
                            <tr *ngFor="let elem of item.newCategories">
                                <td>{{elem.name}}</td>
                                <td>{{elem.count}}<span class="distance">(الاقرب  {{elem.distance}} كم)</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>    
        <div class="row">
            <div class="back">
                <button class="btn btn-cancel" type="button" (click)="back()">
                    رجوع
                </button>  
            </div>
        </div>  
    </div>
</ng-container>
<ng-template #latLong>
    <div class="container">
        <div class="form-group">
            <label for="" class="form-label">ادخل الاحداثيات</label>
            <input type="search" class="form-control" (input)="deleteSpace()"  [(ngModel)]="searchValue"/>
        </div>
        <button class="btn btn-add" (click)="getCategories()">موافق</button>
    </div>
</ng-template>

