@if (showSpinner) {
<app-spinner></app-spinner>
}

<div class="inner-page-header">
  <h3>الخدمات</h3>
</div>
<div class="request-control">
  <div class="right-side">
    <div class="delete-icon-grid">
      <button class="btn btn-custom-delete" [disabled]="disabledDelete">
        <img src="assets/images/svg/delete-icon-ins.svg" alt="" />
      </button>
    </div>
    <quick-search (searchResults)="quickSearch($event)"></quick-search>
  </div>
  <div class="new-element">
    <button class="btn btn-add" [routerLink]="['/pages/services/new']">
      <img src="assets/images/svg/add-icon.svg" alt="" />
      إضافة خدمة
    </button>
  </div>
</div>

<div class="custom-card">
  @if (services) {
  <div class="row custom-container">
    @for (service of services | paginate: config; track service; let i = $index)
    {
    <div class="col-6 item">
      <div>
        <label class="checkbox-container">
          <input type="checkbox" />
          <span class="checkmark"></span>
        </label>
        <span class="checkbox-label">
          {{ service.name }} @if (!service.is_active) {
          <span class="inActive">(غير مفعل)</span>
          }</span
        >
      </div>
      <div class="setting-container">
        <div class="setting-item wrench" (click)="goServiceSettings(service.id)">
          <img src="assets/images/svg/wrench.svg" alt="setting" />
        </div>
        <div class="setting-item wrench" (click)="goServiceDetails(service.id)">
          <img src="assets/images/svg/edit-icon.svg" alt="" />
        </div>
        <div
          class="setting-item delete"
          (click)="showDeleteConfirmationBox(service.id, i, service.name)"
        ></div>
      </div>
    </div>
    }
  </div>
  } @else {
  <div class="empty-services">لا يوجد خدمات</div>
  }
</div>
@if (services && services.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [id]="'dynamicServicePagination'"
  [totalCount]="config.totalItems"
>
</ayen-pagination>
}
