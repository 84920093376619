import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { RatingService } from 'src/app/core/services/rating/rating.service';
import { ReportService } from 'src/app/core/services/report/report.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { InspectionService } from 'src/app/core/services/inspection/inspection.service';
import { AdminOrdersService } from '../../services/orders/admin-orders.service';
import { QcSurveyComponent } from '../../components/qc-survey/qc-survey.component';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { Select, Store } from '@ngxs/store';
import { GetOrderMsg, OrderDetails } from '../../state/order-action';
import { OrderState } from '../../state/order.state';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  panelOpen = {
    clientNotes: false,
    refund: false,
    additionalServices: false,
    mainDetails: false,
    report: false,
    rate: false,
    buildingDetails: false,
    infrastructure: false,
    notes: false,
    inspectionLog: false,
    orderLog: false,
  };
  childPanelOpenState = false;
  latitude: number = 23.8859;
  longitude: number = 45.0792;
  address: string;
  picker: Date;
  orderId: string;
  public orders;
  public orderType;
  public orderTypeEnum = OrderType;
  public orderStatus: any = OrderStatus;
  orderDetailsFormGroup: UntypedFormGroup;
  formatedDate;
  order_status;
  google_address_country;
  google_address_area;
  google_address_city;
  alertmsg: boolean = false;
  confirmMsg: string = 'لقد تم حفظ التعديلات بنجاح.';
  deleteMsg: string = 'هل أنت متأكد من حذف هذا الملف؟';
  modelImg: boolean = true;
  filesData: FileList;
  button: string = 'تم';
  upload: boolean = false;
  orderlabel: any;
  fileIndex: number;
  url: string;
  enableSave: boolean = false;
  errorMessage: string = '';
  qcSurveyTitle = 'تقييم ضمان الجودة';
  btnSubmit = 'تقييم';
  surveyFormData: any;
  ratingData: any;
  qcReviewExist: boolean = false;
  customerReview: any;
  customerReviewExist: any;
  qcReview: any;
  confirmBtn: string;
  disableQC: boolean = false;
  disableCustomer: boolean = false;
  showRating: boolean = false;
  headerTxt: string = 'اعتراض على التقرير';
  rejectReportObject = {
    client_response: 'reject',
    notes: 'ملاحظات اعادة الفحص',
  };
  is_delete: boolean = true;
  backUrl: string;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  qcSurveyDialogRef: MatDialogRef<QcSurveyComponent>;
  reportModalDialogRef: MatDialogRef<ReportModalComponent>;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  orderData$: Observable<any>;
  orderDetailsSubsrciption: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: AdminOrdersService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private titleService: Title,
    private report: ReportService,
    private ratingService: RatingService,
    private inspectotionService: InspectionService,
    private dialog: MatDialog,
    private store: Store
  ) {
    if (
      this.router.getCurrentNavigation()?.extras &&
      this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.rating
    ) {
      this.showRating = true;
    }
  }
  ngOnDestroy(): void {
    this.orderDetailsSubsrciption.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.activatedRoute.params.subscribe((params) => {
      this.orderId = params['id'];
      this.getAdminOrder(this.orderId);
      this.titleService.setTitle('تعديل الطلب #' + this.orderId + '  | عاين');
    });
    this.onChanges();
    this.checkQC();
    this.checkCustomer();
    this.getQCReview();
    this.getCustomerReview();
    if (this.showRating) this.openSurvey();
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {}
  }

  getQCReview() {
    this.ratingService.getQualityControlReview(this.orderId).subscribe(
      (data) => {
        this.qcReview = data.results;
        this.qcReviewExist = data.success;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getCustomerReview() {
    this.ratingService.getCustomerReview(this.orderId).subscribe(
      (data) => {
        this.customerReview = data.results;
        this.customerReviewExist = data.success;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  checkQC() {
    this.ratingService.canAddQCReview(this.orderId).subscribe(
      (data) => {
        this.disableQC = !data.success;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  checkCustomer() {
    this.ratingService.canAddCustomerReview(this.orderId).subscribe(
      (data) => {
        this.disableCustomer = !data.success;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  onChanges(): void {
    this.orderDetailsFormGroup.valueChanges.subscribe((val) => {
      if (this.orderDetailsFormGroup.dirty) this.enableSave = true;
    });
  }

  getAdminOrder(id) {
    this.orderDetailsSubsrciption = this.orderDetails$.subscribe((data) => {
      if (data?.id && data?.id == +this.orderId) {
        this.applyOrderData(data);
      } else {
        this.store.dispatch(new OrderDetails({ orderId: +id })).subscribe(
          (res) => {},
          (error) => {
            try {
              this.confirmMsg = 'الطلب غير موجود';
              this.modelImg = false;
              this.backUrl = '/pages/orders/orders-list';
              this.confirmBtn = 'تم';
              this.showAlertBox();
            } catch (err) {}
          }
        );
      }
    });
  }

  applyOrderData(data) {
    this.orders = data;
    this.order_status = this.orders.status;
    this.orderService.changeOrder(data);
    let loc = this.orders.location.split(',');
    this.longitude = +loc[1];
    this.latitude = +loc[0];
    this.orderType = this.orders.order_type;
    this.orderDetailsFormGroup.patchValue({
      address_neighborhood:
        this.orders.address_neighborhood == 'null'
          ? null
          : this.orders.address_neighborhood,
      address_street:
        this.orders.address_street == 'null'
          ? null
          : this.orders.address_street,
      address_building_code:
        this.orders.address_building_code == 'null'
          ? null
          : this.orders.address_building_code,
      address_unit_code:
        this.orders.address_unit_code == 'null'
          ? null
          : this.orders.address_unit_code,
      order_type: this.orders.order_type,
      is_test: this.orders.is_test,
      is_ayen_express: this.orders.is_ayen_express,
    });
    this.enableSave = false;
  }

  createFormControls() {
    return this.orderDetailsFormGroup.controls;
  }

  createForm() {
    this.orderDetailsFormGroup = this.formBuilder.group({
      address_neighborhood: [null],
      address_street: [null],
      address_building_code: [null],
      address_unit_code: [null],
      order_type: [null],
      is_test: false,
      is_ayen_express: [false],
    });
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        backUrl: this.backUrl,
        button: this.button,
      },
    });
  }

  save() {
    this.enableSave = false;
    let orderUpdated = {};
    orderUpdated = this.orderDetailsFormGroup.value;
    orderUpdated['google_address_city'] = this.google_address_city;
    orderUpdated['google_address_area'] = this.google_address_area;
    orderUpdated['google_address_country'] = this.google_address_country;
    orderUpdated['location'] = this.latitude + ',' + this.longitude;
    if (this.orderDetailsFormGroup.get('order_date').value) {
      this.formatedDate = moment(
        this.orderDetailsFormGroup.get('order_date').value
      );
      this.formatedDate = this.formatedDate.format('YYYY-MM-DD');
      orderUpdated['order_date'] = this.formatedDate;
    }

    this.alertmsg = false;
    if (this.orderDetailsFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      this.errorMessage = 'يوجد خطأ في بعض البيانات';
      if (this.orderDetailsFormGroup.controls.order_periods.value.length == 0) {
        document.querySelector('#order_periods').classList.add('ng-invalid');
      }
      let fields = document.querySelectorAll(
        'ng-select.ng-invalid , mat-form-field.ng-invalid'
      );
      fields.forEach((field) => {
        field.parentElement.classList.add('invalid-field');
      });
      return;
    }
    this.orderService.updateOrder(this.orderId, orderUpdated).subscribe(
      (data) => {
        this.confirmMsg = 'لقد تم حفظ التعديلات بنجاح.';
        this.getAdminOrder(this.orderId);
        this.modelImg = true;
        this.showAlertBox();
      },
      (error) => {
        try {
          this.alertmsg = true;
          this.errorMessage = Object.values(error.error)[0].toString();
        } catch {}
      }
    );
  }

  enableSaveChanges(event) {
    this.enableSave = event.enableSave;
  }

  goList() {
    this.router.navigateByUrl('/pages/orders/orders-list');
    this.titleService.setTitle('الطلبات | عاين');
  }

  mapValues(event) {
    this.google_address_city = event.city ? event.city : '';
    this.google_address_country = event.country;
    this.google_address_area = event.state;
    this.enableSave = true;
    this.latitude = event.latitude;
    this.longitude = event.longitude;
  }

  openSurvey() {
    this.qcSurveyDialogRef = this.dialog.open(QcSurveyComponent, {
      data: {
        orderID: this.orderId,
        title: 'تقييم ضمان الجودة',
        btnSubmit: 'تقييم',
        formData: undefined,
      },
    });
    this.qcSurveyDialogRef.afterClosed().subscribe( data => {
      if(data?.results) {
        this.surveyData(data);
      }
    });
  }

  changeQCSurveyData(event) {
    this.qcSurveyTitle = event.title;
    this.surveyFormData = event.data;
    this.btnSubmit = event.btnSubmit;
  }

  surveyData(event) {
    this.ratingData = event;
    this.qcReviewExist = event.success;
  }

  updateData() {
    this.orderService.updateZapierData(this.orderId).subscribe(
      (data) => {
        this.confirmMsg = 'تم تحديث البيانات بنجاح.';
        this.modelImg = true;
        this.showAlertBox();
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  notifyInspectorsWithOrder() {
    this.orderService.notifyInspectors(this.orderId).subscribe(
      (data) => {
        this.confirmMsg = 'تم تنبيه الفاحصين بنجاح.';
        this.modelImg = true;
        this.showAlertBox();
      },
      (error) => {
        this.confirmMsg = error.error.message;
        this.modelImg = false;
        this.button = 'اغلاق';
        this.showAlertBox();
      }
    );
  }

  saveReportEdit(event) {
    this.store.dispatch(new OrderDetails({ orderId: +this.orderId }));
    this.confirmMsg = event.message;
    this.modelImg = event.imgSuccess;
    this.button = event.button;
    this.openSurvey();
  }

  applyEdit(event) {
    this.rejectReportObject['notes'] = event;
    this.RejectReport();
  }

  RejectReport() {
    this.report.acceptReport(this.orderId, this.rejectReportObject).subscribe(
      (data) => {
        this.store.dispatch(new GetOrderMsg({ orderId: +this.orderId }));
        this.store.dispatch(new OrderDetails({ orderId: +this.orderId }));
        this.confirmMsg =
          'تم إرسال الملاحظات لمزود الخدمة وسيتم العمل على تنفيذ ملاحظاتكم في أقرب وقت ممكن.';
        this.modelImg = true;
        this.button = 'تم';
        this.showAlertBox();
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  showMSG(event) {
    this.confirmMsg = event.message;
    this.modelImg = event.imgSuccess;
    this.button = 'إغلاق';
    this.showAlertBox();
  }

  hideErrorMSG() {
    this.alertmsg = false;
  }

  requestReinspection() {
    let data = {
      orders: [Number(this.orderId)],
    };
    this.inspectotionService.requestReinspection(data).subscribe(
      (data) => {
        this.confirmMsg = 'تم إنشاء طلب إعادة فحص  #' + this.orderId + '.';
        this.modelImg = true;
        this.button = 'تم';
        this.showAlertBox();
      },
      (error) => {
        try {
          this.confirmMsg = error.error.message;
          this.modelImg = false;
          this.showAlertBox();
        } catch {}
      }
    );
  }

  duplicatOrder(id) {
    this.orderService.duplicateOrder(id).subscribe(data => {
      this.modelImg = true;
      this.confirmMsg = 'تم نسخ الطلب  بنجاح  رقم الطلب الجديد #'+ data.new_reference_number;
      this.showAlertBox();
    }, (error) => {
      try{
        this.confirmMsg = error.error;
        this.modelImg = false;
        this.showAlertBox();
      }
      catch{}
    });
  }
}
