import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuildingType } from 'src/app/models/enums/building-type.enum';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { AdminOrdersService } from '../../../services/orders/admin-orders.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {
  @Output() summary_view = new EventEmitter();
  order: any;
  @Input() orderId;
  addCoupon: boolean = true;
  couponData;
  couponFormGroup: any;
  couponValue: any;
  couponObject: {};
  title: string;
  confirmBtn = 'تطبيق';
  confirmMsg;
  valid: boolean = false;
  modelImg: boolean;
  address_building_code: string = '';
  address_unit_code: string = '';
  address_neighborhood: string = '';
  address: string = '';
  ayenExpress: any;
  orderTitle: string = '';
  ayenVisit: boolean;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  orderType = OrderType;
  Loader: boolean = true;
  buildingType = BuildingType;
  hideSummary = true;

  constructor(
    private orderService: AdminOrdersService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAdminOrder();
  }

  getAdminOrder() {
    this.orderService.getAdminOrder(this.orderId).subscribe(
      (data) => {
        this.order = data;
        this.Loader = false;
        this.orderId = data.id;
        this.address_building_code = this.order.address_building_code
          ? this.order.address_building_code + ' , '
          : '';
        this.address_unit_code = this.order.address_unit_code
          ? this.order.address_unit_code + ' , '
          : '';
        this.address_neighborhood = this.order.address_neighborhood
          ? this.order.address_neighborhood + ' , '
          : '';
        this.address = this.address_building_code;
        this.address += this.address_neighborhood;
        this.address += this.order.google_address_city
          ? this.order.google_address_city + ','
          : '';
        this.address += this.order.google_address_area
          ? this.order.google_address_area + ','
          : '';
        this.address += this.order.google_address_country
          ? this.order.google_address_country
          : '';
        if (
          this.order?.order_details?.order_type_object?.id !=
          this.orderType.DynamicServices
        ) {
          this.orderTitle = this.order?.order_details?.order_type_object?.name;
          this.hideSummary = false;
        } else {
          this.orderTitle = this.order?.order_details?.dynamic_service?.name;
          this.hideSummary = true;
        }
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  prevStep() {
    this.summary_view.emit({ view: 'inspectoring', id: this.orderId });
  }

  getPriods(periodsList) {
    let periods = '';
    periodsList.forEach((element) => {
      periods += element.name + ', ';
    });
    periods = periods.slice(0, -2);
    return periods;
  }

  applyCoupon(couponObject) {
    if (this.confirmBtn === 'تطبيق') {
      couponObject = { order_pk: this.orderId, coupon_code: this.couponValue };
      this.orderService.applyCoupon(couponObject).subscribe(
        (data) => {
          this.couponData = data;
          if (this.couponData.success == true) {
            this.confirmMsg = 'تم تطبيق كود الخصم بنجاح ';
            this.modelImg = true;
            this.showAlertBox();
            this.order.cost.final_cost =
              this.couponData.cost_summary.final_cost;
            this.order.cost.cost_after_discount =
              this.couponData.cost_summary.cost_after_discount;
            this.order.cost.tax_cost = this.couponData.cost_summary.tax_cost;
            this.valid = this.couponData.success;
            this.confirmBtn = 'حذف';
          } else {
            this.modelImg = false;
            this.confirmBtn = 'تطبيق';
            this.confirmMsg = 'كود الخصم غير صالح';
            this.showAlertBox();
          }
        },
        (error) => {
          this.handleBackendError(error);
        }
      );
    } else if (this.confirmBtn === 'حذف') {
      this.couponValue = '';
      couponObject = { order_pk: this.order.id, coupon_code: null };
      this.orderService.applyCoupon(couponObject).subscribe(
        (data) => {
          this.modelImg = true;
          this.valid = false;
          this.showAlertBox();
          this.confirmMsg = 'تم حذف كود الخصم بنجاح ';
          this.confirmBtn = 'تطبيق';
          this.couponData = data;
          this.order.cost.final_cost = this.couponData.cost_summary.final_cost;
          this.order.cost.cost_after_discount =
            this.couponData.cost_summary.cost_after_discount;
          this.order.cost.tax_cost = this.couponData.cost_summary.tax_cost;
        },
        (error) => {
          this.handleBackendError(error);
        }
      );
    }
  }

  showAlertBox(backUrl?) {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        button: 'تم',
        backUrl: backUrl ? backUrl : null,
      },
    });
  }

  handleBackendError(error) {
    try {
    } catch {}
  }

  goOrderList() {
    this.confirmMsg = 'تم إنشاء الطلب بنجاح.';
    this.modelImg = true;
    let backUrl = this.order.contract?.pk ? '/pages/projects/orders' :'/pages/orders/orders-list';
    this.showAlertBox(backUrl);
  }

  copyOrderLink() {
    if(environment.production) {
      this.copyLink(`https://services.ayen.com.sa/add-request/payment?id=${this.orderId}`);
    } else {
      this.copyLink(`https://alpha.services.ayen.com.sa/add-request/payment?id=${this.orderId}`)
    }
  }

  copyLink(link:string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.modelImg = true;
    this.confirmMsg = 'تم النسخ بنجاح';
    this.showAlertBox();
  }
}
