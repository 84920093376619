import { Component, Input, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'security-error',
  templateUrl: './security-error.component.html',
})
export class SecurityErrorComponent implements OnInit {

  message: string;

  constructor(
    public dialogRef: MatDialogRef<SecurityErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.message = this.data.message ?? 'new test';
  }
}
