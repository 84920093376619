import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProjectDetailsComponent } from "./pages/project-details/project-details.component";
import { ProjectsListComponent } from "./pages/projects-list/projects-list.component";
import { ProjectsOrdersComponent } from "./pages/projects-orders/projects-orders.component";
import { ProjectsComponent } from "./projects.component";
import { RoleGuard } from "src/app/core/guards/role-guard/role-guard.guard";
import { UserType } from "src/app/models/enums/user-type.enum";

const routes: Routes = [
    {
      path: '',
      component: ProjectsComponent,
      children: [
        { path: '', pathMatch: 'full', redirectTo: 'list' },
        { path: 'list', component: ProjectsListComponent ,
          canActivate: [RoleGuard],
          data: {
            expectedRole: [UserType.Admin, UserType.Supervisor, UserType.AssignmentOffice, UserType.InspectorActivationOffice]
          },
        },
        { path: 'orders', component: ProjectsOrdersComponent },
        { path: ':id', component: ProjectDetailsComponent,},
        { path: ':add', component: ProjectDetailsComponent}
      ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class ProjectsRoutingModule{}
