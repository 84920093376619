import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Images } from 'src/app/shared/global/image-constants';
import { tablesHeaderData } from 'src/app/shared/global/tablesHeaderData';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { Order } from 'src/app/models/interfaces/order';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { AssignInspectorComponent } from 'src/app/shared/components/assign-inspector/assign-inspector.component';
import { AssignOrdersProjectComponent } from 'src/app/shared/components/assign-orders-project/assign-orders-project.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { ProjectsService } from '../../services/projects.service';
import { AdminOrdersService } from 'src/app/features/orders/services/orders/admin-orders.service';
import { ColumnsUtilService } from 'src/app/core/services/uitls/columns-util/columns-util.service';

@Component({
  selector: 'app-projects-orders',
  templateUrl: './projects-orders.component.html',
  styleUrls: ['./projects-orders.component.scss'],
})
export class ProjectsOrdersComponent implements OnInit {
  orders: Order[] = [];
  orderType: any;
  orderStatus: any = OrderStatus;
  searchValue: string;
  totalRecords: number = 0;
  page: number = 1;
  config: any;
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  deletedOrderID: any[] = [];
  deletedOrderIndex: any[] = [];
  modelImg: boolean;
  imgPath: string;
  confirmMsg: string;
  disabledDelete: boolean = true;
  filterData;
  imgDefault: string = Images.DEFAULT_SORT;
  imgASC: string = Images.ASC_SORT;
  imgDesc: string = Images.DESC_SORT;
  filterDataInit = {};
  filterParamsUrl = {};
  name: string;
  matDialogRef: MatDialogRef<AssignInspectorComponent>;
  orderDialogRef: MatDialogRef<AssignOrdersProjectComponent>;
  assignedInspectorId: any;
  spinner: boolean = true;
  filterPage: string = 'ORDERS';
  pageTitle: string = 'الطلبات';
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  savedFilters: any = [];
  spinnerKey: string = 'spinner';
  tableHeaderData = tablesHeaderData.PROJECTS_ORDER_HEADER_DATA;
  updatedOrders = new BehaviorSubject({});
  orderData = this.updatedOrders.asObservable();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  totalRowsSelected: boolean = false;
  currentItemsPerPage: number;

  constructor(
    private router: Router,
    private adminOrder: AdminOrdersService,
    private titleService: Title,
    private route: ActivatedRoute,
    private filter: FiltersService,
    private ProjectsService: ProjectsService,
    private filterUtil: FilterUtilService,
    private matDialog: MatDialog,
    private columnsUtil: ColumnsUtilService
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.searchValue = params['query'] ? params['query'] : '';
        this.filterParamsUrl = params;
        this.filterDataInit = params;
      }
    });
    this.getOrdersFilter();
    this.titleService.setTitle(' طلبات المشاريع | عاين');
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(this, this.getProjectsOrdersByPage, this.spinnerKey);
    this.columnsUtil.applyColumnsData(this.tableHeaderData);
  }

  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getProjectsOrdersByPage();
  }

  emptySelectedRows() {
    this.selectedRowsIndex.splice(0, this.selectedRowsIndex.length);
    this.selectedRowsPK.splice(0, this.selectedRowsPK.length);
  }

  getProjectsOrdersByPage(ordering?) {
    this.emptySelectedRows();
    let params = { ...this.filterParamsUrl };
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;
    this.totalRowsSelected = false;
    if (this.searchValue) {
      params['query'] = this.searchValue;
    }
    if (ordering) {
      params['ordering'] = ordering;
    }
    this.ProjectsService.getProjectsOrdersByPage(params).subscribe(
      (data) => {
        this.loadOrders(data);
        this.updatedOrders.next(data.results);
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  loadOrders(data) {
    this.spinner = false;
    this.orders = data.results;
    this.config['totalItems'] = data.count;
    this.currentItemsPerPage = data.results.length;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.filterParamsUrl,
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  getOrdersFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe(
      (data) => {
        this.filterData = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
    } catch {}
  }

  getSelectedRow(event) {
    this.selectedRowsIndex = event.index;
    this.selectedRowsPK = event.id;
    this.totalRowsSelected = false;
  }

  deleteSelectedRows() {
    this.deletedOrderID = this.selectedRowsPK;
    this.deletedOrderIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }

  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.matDialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedOrderID,
        index: this.deletedOrderIndex,
        message: 'هل أنت متأكد من حذف الطلب',
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(result);
      }
    });
  }

  delete(event) {
    let index = event.index;
    let pk = event.id;
    this.adminOrder.deleteAdminOrder(pk).subscribe(
      (data) => {
        for (let i = index.length - 1; i >= 0; i--) {
          let OrderInd = index[i];
          this.orders.splice(OrderInd, 1);
        }
        this.selectedRowsPK = [];
        this.selectedRowsIndex = [];
      },
      (error) => {
        try {
          this.modelImg = false;
          this.imgPath = Images.REFUSAL_ICON;
          this.confirmMsg = '  عذراً لا يمكن حذف الطلب . ‎';
          this.showAlertBox();
          this.selectedRowsPK = [];
          this.selectedRowsIndex = [];
        } catch {}
      }
    );
  }

  showAlertBox() {
    this.saveDialogRef = this.matDialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        imgPath: this.imgPath,
        button: 'تم',
      },
    });
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.totalRowsSelected = false;
    this.getProjectsOrdersByPage();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getProjectsOrdersByPage();
  }
  onFilterTextBoxChanged() {
    this.config.currentPage = 1;
    this.getProjectsOrdersByPage();
  }

  filterFormValues(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.spinner = true;
    this.config.currentPage = 1;
    this.getProjectsOrdersByPage();
  }

  toggleSorting(event) {
    this.getProjectsOrdersByPage(event.col);
  }

  disabledDeleteBtn(event) {
    this.disabledDelete = event;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }

  sipnnerState(state) {
    this.spinner = state;
  }

  updateOrderStatus(event) {
    if(event.done) {
     this.confirmMsg = 'تم تغير حالة الطلبات بنجاح ';
     this.modelImg = true;
     this.showAlertBox();
     this.config.currentPage = 1;
      this.getProjectsOrdersByPage();
    }
    else {
     this.modelImg = false;
      this.confirmMsg = event.error;
      this.showAlertBox();
    }
   }
}
