import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { UserType } from 'src/app/models/enums/user-type.enum';
import { RedirectionUrls } from 'src/app/shared/global/redirectionsUrls';
/**
  declare login component
 *  
*/
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginFormGroup: UntypedFormGroup;
  emailControl: UntypedFormControl;
  userNameControl: UntypedFormControl;
  passwordControl: UntypedFormControl;
  errorAuth: boolean = false;
  isLoggedIn = false;
  returnUrl: any;
  user = UserType;
  redirectionUrls = RedirectionUrls.redirectRoot;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private titleService: Title
  ) { }

  ngAfterViewInit() {
    document.querySelector('body').classList.add('login');
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('login');
  }

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.titleService.setTitle('تسجيل الدخول | عاين');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  createFormControls() {
    return this.loginFormGroup.controls;
  }

  createForm() {
    this.loginFormGroup = this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
      remember_me: [false],
    });
  }

  remeberMe(remeber) {
    this.isLoggedIn = remeber.checked;
  }

  save(): void {
    localStorage.clear();
    this.authenticationService.authLogin(this.loginFormGroup.value).subscribe(
      (data) => {
        this.errorAuth = false;
        if (![this.user.Client, this.user.Inspector].includes(data.user.profile.user_type)) {
          this.authenticationService.setIsLoggedIn(this.isLoggedIn);
          localStorage.setItem('currentUser', JSON.stringify(data));
          if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigateByUrl(this.redirectionUrls[data.user.profile.user_type].url);
          }
          this.titleService.setTitle('الرئيسية | عاين');
        } else {
          this.errorAuth = true;
        }
      },
      () => {
        try {
          this.errorAuth = true;
        } catch (err) { }
      }
    );
  }
  closeError() {
    this.errorAuth = false;
  }
}
