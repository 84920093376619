<button class="btn btn-custom-delete" #t="matMenuTrigger" 
  mat-button [matMenuTriggerFor]="menu" (menuOpened)="menuOpened()">
  <img src="assets/images/svg/edit-icon.svg" alt="" />
</button>
<mat-menu #menu="matMenu" backdropClass="colms-menu">
  <div class="title">
    <h2>اظهار / اخفاء الاعمدة</h2>
  </div>
  <div class="cols-wrapper">
    @for(column of columns; track column;let i=$index) {
      @if(column.value!='reference_number') {
        <div class="col-header">
          <label class="checkbox-container" (click)="stopPropagation($event)">
            <input type="checkbox" 
              (click)="toggleVisibleColumn(i)"
              [attr.checked]="column.visible" 
            />
            <span class="checkmark"></span>
          </label>
          <span class="checkbox-label">{{column.display_name}}</span>
        </div>
      }
    }
  </div>
</mat-menu>
