import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CompanyServicesService } from 'src/app/features/companies/services/company-services/company-services.service';
import { AyenServices } from 'src/app/core/services/ayen-services/ayen-services.service';
import { DynamicServices } from 'src/app/features/ayen-services/services/dynamic-services/dynamic-services.service';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  title: string = 'إضافة مشروع';
  saveBtn: string = 'إضافة';
  projectFormGroup: UntypedFormGroup;
  companies: any[] = [];
  companiesPageCount: any;
  companiesPage: number = 1;
  inspectors: any[] = [];
  servicePage: number = 1;
  @ViewChild('selectcomp') selectcomp: NgSelectComponent;
  companySearchInput: string;
  inspectorSearchInput: string = '';
  timeout: any = null;
  fileName: string = '';
  fileSizeErr: boolean = false;
  formData: any = new FormData();
  routeParam: any;
  services: any[] = [];
  alertmsg: boolean = false;
  message: string;
  projectID: any;
  initServices: number[] = [];
  initDynamicServices: any[] = [];
  initInspectors: number[] = [];
  backUrl: string = '/pages/projects/list';
  MAX_FILE_SIZE: number = 5000000;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  dynamicServicesItemsData = new BehaviorSubject({});
  serviceData = this.dynamicServicesItemsData.asObservable();
  inspectorsItemsData = new BehaviorSubject({});
  inspectorData = this.inspectorsItemsData.asObservable();
  page: any = 1;
  inspectorPage: any = 1;
  page_count: any = 20;
  inspector_page_count: any = 0;
  params: {};
  contractAcquiringUnitUrl: any;
  searchInput: string;
  dynamicServicesArr: any[] = [];
  dynamicServiceselements: any[] = [];
  dynamicEvents: any[]=[];
  constructor(
    private formBuilder: FormBuilder,
    private inspectorService: InspectorsService,
    private ayenServices: AyenServices,
    private inspectorsService: InspectorsService,
    private companyService: CompanyServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private dynamicServices: DynamicServices,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getCompanies();
    this.getServices();
    this.getDynamicServices();
    this.getInspectors();
    this.routeParam = this.activatedRoute.snapshot.params.id;
    if (this.routeParam != 'add') {
      this.title = 'عرض / تعديل المشروع';
      this.saveBtn = 'حفظ';
      this.projectID = this.routeParam;
      this.getProjectByID();
    }
  }

  createForm() {
    this.projectFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      company: [null, Validators.required],
      shortcode: [null],
      active_date: [null, Validators.required],
      expiry_date: [null, Validators.required],
      contract_file: [null],
      inspectors: [null],
      description: [null],
      units_count: [0],
      services: [null, Validators.required],
      dynamic_services: [null],
      is_acquirable: [false],
    });
  }

  createFormControls() {
    return this.projectFormGroup.controls;
  }

  getProjectByID() {
    this.projectsService.getProjectByID(this.projectID).subscribe(
      (data) => {
        this.initServices = data.services;
        this.initInspectors = data.inspectors;

        this.initDynamicServices = data?.dynamic_services;
        this.fileName = data.contract_file
          ? this.sliceFileName(data.contract_file)
          : '';
        this.patchValues(data);
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  patchValues(data) {
    this.updateDynamicService(data);
    this.updateInspectorsList(data);
    this.contractAcquiringUnitUrl = data.contract_acquiring_unit_url;
    this.projectFormGroup.patchValue({
      name: data.name,
      company: data.company.pk,
      shortcode: data.shortcode,
      active_date: moment(data.active_date),
      expiry_date: moment(data.expiry_date),
      contract_file: data.contract_file,
      inspectors: data.inspectors,
      description: data.description,
      units_count: data.units_count,
      services: data.services,
      is_acquirable: data.is_acquirable,
      dynamic_services: data.dynamic_services,
    });
  }

  updateDynamicService(data) {
    data.dynamic_services.forEach((element) => {
      this.dynamicServices.getServiceById(element).subscribe((serviceData) => {
        this.dynamicServicesArr = [...new Set(this.dynamicServicesArr)];
        if (
          !this.dynamicServicesArr.find((item) => item.id === serviceData.id)
        ) {
          this.dynamicServicesArr.push({
            id: serviceData.id,
            name: serviceData.name,
          });
        }
      });
    });
  }
  updateDynamicServices(event) {
    if (event.reset) {
      this.page = 1;
      this.searchInput = '';
    } else {
      this.page = event.page ? event.page : this.servicePage;
      this.searchInput = event?.searchInput;
    }
    this.getDynamicServices();
  }

  updateInspectorsList(data) {
    data.inspectors.forEach((element) => {
      this.inspectorService.getInspectorByID(element).subscribe((inspectorData) => {
        this.inspectors = [...new Set(this.inspectors)];
        if (
          !this.inspectors.find((item) => item.id === inspectorData.id)
        ) {
          this.inspectors.push({
            id: inspectorData.id,
            full_name: inspectorData.full_name,
            is_active: inspectorData.is_active
          });
        }
      });
    });
  }

  getCompanies() {
    const params = { page: this.companiesPage };
    this.companyService.getCompaniesList(params).subscribe((data) => {
      this.companies.push(...data.results);
      this.companiesPageCount = data.pages_count;
    });
  }

  resetSearch() {
    this.companies = [];
    this.companiesPage = 1;
    this.getCompanies();
  }

  onScrollPagination() {
    if (this.companiesPage < this.companiesPageCount) {
      this.companiesPage++;
      this.getCompanies();
    }
  }

  searchText() {
    this.companySearchInput = this.selectcomp.searchTerm;
    this.companies = [];
    clearTimeout(this.timeout);
    let that = this;
    that.timeout = setTimeout(() => {
      if (that.companySearchInput != '') {
        that.getCompanies();
      } else {
        this.companiesPage = 1;
        that.getCompanies();
      }
    }, 600);
  }

  sliceFileName(name) {
    return name.slice(name.lastIndexOf('/') + 1, name.indexOf('?'));
  }

  deleteFile() {
    this.fileName = '';
    this.projectFormGroup.get('contract_file').setValue(null);
  }

  handleFileInput(files) {
    this.fileSizeErr = false;
    this.fileName = files[0].name;
    const size = files[0].size;
    if (size < this.MAX_FILE_SIZE) {
      this.formData.append('contract_file', files[0], this.fileName);
    } else {
      this.fileSizeErr = true;
    }
  }

  getServices() {
    this.ayenServices.getAyenServices().subscribe(
      (data) => {
        this.services = data;
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  getDynamicServices() {
    let params = {
      page: this.page,
      is_active: true,
    };
    if (this.searchInput && this.searchInput.length > 0) {
      params['name'] = this.searchInput;
    }
    this.dynamicServices.getServices(params).subscribe((data) => {
        this.page_count = data.pages_count;
        let results = data.results;
        let ids = new Set(this.dynamicServicesArr.map(d => d.id));
       this.dynamicServicesArr = [...this.dynamicServicesArr, ...results.filter(d => !ids.has(d.id))];
        this.dynamicServicesItemsData.next(this.dynamicServicesArr);    
    });
  }

  setServices(event) {
    this.projectFormGroup.controls.services.patchValue([...event]);
  }

  onScrollOptions(event) {
    if (event && this.page < this.page_count) {
      this.page++;
      this.params = { page: this.page };
      this.getDynamicServices();
    }
  }
  onScrollInspectorOptions(event) {
    if (event && this.page < this.page_count) {
      this.page++;
      this.params = { page: this.page };
      this.getInspectors();
    }
  }
  setDynamicServices(event) {
    this.initDynamicServices = event;
    this.projectFormGroup.controls.dynamic_services.patchValue([...event]);
  }

  getInspectors() {
    let params = {
      page: this.page,
      page_size: 10
    };
    if (this.inspectorSearchInput && this.inspectorSearchInput.length > 0) {
      params['full_name'] = this.inspectorSearchInput;
    }
    this.inspectorsService.getAvailableInspectors(params).subscribe((data) => {
        this.page_count = data.pages_count;
        let results = data.results;
        let ids = new Set(this.inspectors.map(d => d.id));
        this.inspectors = [...this.inspectors, ...results.filter(d => !ids.has(d.id))];
        this.inspectorsItemsData.next(this.inspectors);    
    });
  }

  setInspectors(event) {
    this.initInspectors = event;
    this.projectFormGroup.controls.inspectors.patchValue([...event]);
  }

  updateInspectors(event) {
    if (event.reset) {
      this.page = 1;
      this.inspectorSearchInput = '';
    } else {
      this.page = event.page ? event.page : this.inspectorPage;
      this.inspectorSearchInput = event?.searchInput;
    }
    this.getInspectors();
  }

  goProjects() {
    this.router.navigateByUrl('/pages/projects/list');
  }

  updateFormWithFormatedDate(formControl) {
    if (this.projectFormGroup.get(formControl).value) {
      let dateValue = moment(this.projectFormGroup.get(formControl).value);
      let formatedDate = dateValue.format('YYYY-MM-DD');
      this.projectFormGroup.get(formControl).setValue(formatedDate);
    }
  }

  save() {
    this.updateFormWithFormatedDate('active_date');
    this.updateFormWithFormatedDate('expiry_date');
    this.alertmsg = false;
    if (this.projectFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      this.message =
        'هناك خطأ في خانة أو بعض الخانات يرجى التأكد من المعلومات المدخلة';
    } else {
      this.appendFormData();
      if (this.projectID) {
        this.updateProject();
      } else {
        this.createProject();
      }
    }
  }

  appendFormData() {
    for (const [key, value] of Object.entries(this.projectFormGroup.value)) {
      if (key != 'contract_file' && value != null) {
        if (
          key == 'inspectors' ||
          key == 'services' ||
          key == 'dynamic_services'
        ) {
          let values = this.projectFormGroup.get(key).value;
          values.forEach((val) => {
            this.formData.append(key, val);
          });
        } else {
          this.formData.append(key, value);
        }
      }
    }
  }

  createProject() {
    this.projectsService.createProject(this.formData).subscribe(
      (data) => {
        const msg = `تم إضافة مشروع ( ${data.name}) بنجاح.`;
        this.showAlertBox(msg, true);
      },
      (error) => {
        try {
          this.handleError(error);
        } catch {}
      }
    );
  }

  updateProject() {
    this.projectsService.updateProject(this.projectID, this.formData).subscribe(
      (data) => {
        const msg = `تم تعديل مشروع ( ${data.name}) بنجاح.`;
        this.showAlertBox(msg, true);
      },
      (error) => {
        try {
          this.handleError(error);
        } catch {}
      }
    );
  }

  showAlertBox(msg, imgStatue) {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: imgStatue,
        message: msg,
        backUrl: this.backUrl,
        button: 'تم',
      },
    });
  }

  handleError(error) {
    this.alertmsg = true;
    this.message = Object.values(error.error)[0].toString();
  }

  closeError() {
    this.alertmsg = false;
  }
  clientStatus(event) {
    this.projectFormGroup.get(event.labelEn).setValue(event.value);
  }
  goToOrders() {
    this.router.navigate(['/pages/projects/orders'], {
      queryParams: { contract: this.projectID },
    });
  }

  copyReportLink() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.contractAcquiringUnitUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
