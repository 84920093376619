import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColumnsUtilService {

  private columns = new BehaviorSubject([]);
  public currentColState = this.columns.asObservable();

  constructor() { }

  applyColumnsData(columns) {
    this.columns.next(columns);
  }

}
