import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorPaletas } from 'src/app/common/colorPalates';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { StatisticsService } from 'src/app/core/services/statistics/statistics.service';
import { Charts } from 'src/app/core/services/uitls/charts';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { PieChartParams } from 'src/app/models/enums/charts.enum';
import { Images } from 'src/app/shared/global/image-constants';
import { OrderStatusColors } from 'src/app/util/order-status-colors';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit{
  cardsData: any[] = [];
  valueDescription: any;
  cities: any[] = [];
  imagePath = Images;
  filterPage: string = 'DASHBOARD';
  filterData: any;
  filterDataInit = {};
  colorPalet80 = ColorPaletas.colorPalet80;
  colorPalet40 = ColorPaletas.colorPalet40;
  colorPalet30 = ColorPaletas.colorPalet30;
  filterParamsUrl = {};
  regionMap: any;

  constructor(
    private orderStats: StatisticsService,
    private route: ActivatedRoute,
    private filterUtil: FilterUtilService,
    private router: Router,
    private filter: FiltersService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.filterUtil.clearfilterParams(this.filterParamsUrl);
      this.filterUtil.clearfilterParams(this.filterDataInit);
      if (Object.keys(params).length > 0) {
        this.filterParamsUrl = { ...params };
        this.filterDataInit = { ...params };
      }
    });
    this.filter.changeSelectedFilter(this.filterDataInit);
    
    this.getDashboardFilter();
  }
  ngAfterViewInit(): void {
    this.getStatisitics();
  }
  mapRegionData(data) {
    return data.map((val) => {
      data[val.map_code] = val;
    });
  }
  getStatisitics() {
    let params = { ...this.filterParamsUrl };
    this.orderStats.getOrderStats(params).subscribe((data) => {
      setTimeout(()=>{
        this.cities = data.orders_cities;
        this.regionMap = data.orders_regions;
        this.mapRegionData(this.regionMap);
        this.createOrderStatusChart(data.orders_statuses);
        this.createOrderHistoryChart(data.orders_history);
        this.createOrderDonutChart(data.orders_types);
        this.createBuildingTypesChart(data.orders_building_types);
        this.createCardsData(data.cards);
      },500)
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.filterParamsUrl,
        queryParamsHandling: '',
      });
    });
  }

  createCardsData(cards) {
    this.cardsData = [];
    cards.forEach((elm) => {
      if (elm.key !== 'refunded_amount') {
        this.cardsData.push(elm);
      } else {
        this.valueDescription = elm.value;
      }
    });
  }

  getDashboardFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe((data) => {
      this.filterData = data;
    },
      (error) => {
        try { }
        catch { }
      }
    );
  }

  filterFormValues(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.getStatisitics();
  }
  
  createOrderDonutChart(orderTypes) {
    let orderTypeData = [];
    setTimeout(() => {
      orderTypes.forEach((status, index) => {
        orderTypeData.push({
          name: status.name ?? 'لا يوجد',
          y: status.count,
          color: this.colorPalet40[index],
        });
      });
      Charts.createlargeDonoutChart({
        data: orderTypeData,
        name: 'انواع الطلب',
        id: `orderTypes`,
      });
    }, 0);
  }

  createOrderStatusChart(ordersStatuses) {
    let orderStatusData = [];
    ordersStatuses.forEach((status, index) => {
      orderStatusData.push({
        name: status.name ?? 'لا يوجد',
        y: status.count,
        color: OrderStatusColors.getOrderStatusColor(status.status),
      });
    });
    const orderStatusParams: PieChartParams = {
      id: 'orderStatus',
      series: orderStatusData,
      name: 'حالات الطلب',
      height: 250,
      fontSize: '8px',
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
      }
    };
    Charts.createStylesPie(orderStatusParams);
  }

  createBuildingTypesChart(buildingTypes) {
    let buildingTypesData = [];
    buildingTypes.forEach((status, index) => {
      buildingTypesData.push({
        name: status.name ?? 'لا يوجد',
        y: status.count,
        color: this.colorPalet30[index],
      });
    });
    const buildingTypesParams: PieChartParams = {
      id: 'buildingTypes',
      series: buildingTypesData,
      name: 'نوع الوحدة',
      height: 400,
      fontSize: '8px',
    };
    Charts.createStylesPie(buildingTypesParams);
  }
  
  createOrderHistoryChart(ordersHistory) {
    let data = ordersHistory.slice(ordersHistory.length - 2);
    data = data.map((item, index) => ({
      name: item.year,
      color: ColorPaletas.historyColors[index],
      data: item.data.map((data) => data.count),
    }));
    const params = {
      id: 'orderHistory',
      height: 400,
      data: data,
      name: 'الطلبات لأخر عامين '
    };
    Charts.createSplineChart(params);
  }
}
