import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { OrderDetailsService } from '../../../services/order-details/order-details.service';
import { LocationsService } from 'src/app/core/services/locations/locations.service';
import { Select, Store } from '@ngxs/store';
import {
  GetPropertylocation,
  OrderDetails,
  PostPropertyLocation,
} from '../../../state/order-action';
import { OrderState } from '../../../state/order.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'property-location',
  templateUrl: './property-location.component.html',
  styleUrls: ['./property-location.component.scss'],
})
export class PropertyLocationComponent implements OnInit {
  @Input() orderID: any;
  @Output() errorMsg = new EventEmitter();
  propertyLocationData: any;
  propertyLocationForm: UntypedFormGroup;
  modelImg: boolean;
  button: string;
  confirmMsg: string;
  showSpinner: boolean;
  cities = [];
  @Select(OrderState.getPropertyLocation) propertyLocation$: Observable<any>;
  alertmsg: boolean = false;

  constructor(
    private locationsService: LocationsService,
    private store: Store,
    private orderDetailsService: OrderDetailsService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getPropertyLocation();
    this.getAllCities();
  }

  createForm() {
    this.propertyLocationForm = this.formBuilder.group({
      district_ar: [null, Validators.required],
      street_ar: [null, Validators.required],
      _city: [null, Validators.required],
      zip_code: [null, Validators.required],
      location: [null, Validators.required],
      additional_number: [null, Validators.required],
      building_number: [null, Validators.required],
      unit_number: [],
      short_address: [null, Validators.required],
    });
  }

  createFormControls() {
    return this.propertyLocationForm.controls;
  }

  patchValues() {
    this.propertyLocationForm.patchValue({
      district_ar: this.propertyLocationData.district_ar,
      street_ar: this.propertyLocationData.street_ar,
      _city: this.propertyLocationData._city,
      zip_code: this.propertyLocationData.zip_code,
      location: this.propertyLocationData.location,
      additional_number: this.propertyLocationData.additional_number,
      building_number: this.propertyLocationData.building_number,
      unit_number: this.propertyLocationData.unit_number,
      short_address: this.propertyLocationData.short_address,
    });
  }

  getPropertyLocation() {
    this.store
      .dispatch(new GetPropertylocation({ orderId: +this.orderID }))
      .subscribe((data) => {
        this.propertyLocationData = data.OrderState.propertyLocation;        ;
        if (this.propertyLocationData) {
          this.patchValues();
        }
      });
  }

  getAllCities() {
    this.locationsService.getCoveredCities().subscribe((data) => {
      this.cities = data;
    });
  }

  save() {
    if (this.propertyLocationForm.valid) {
      this.alertmsg = false ;
      let data = this.propertyLocationForm.value;
      this.orderDetailsService.postPropertyLocation(this.orderID,data)
        .subscribe(
          (res) => {
            this.showSpinner = false;
            this.showConfirmationModal('تم تحديث البيانات بنجاح.', true);
            this.store
              .dispatch(new OrderDetails({ orderId: +this.orderID }))
              .subscribe((res) => {});
          },
          (error) => {
            this.showSpinner = false;
            this.showConfirmationModal(error.error.error[0], false);
          }
        );
    }
    else {
     this.alertmsg = true;
    }
 
  }

  showConfirmationModal(msg, imgSuccess) {
    this.errorMsg.emit({
      message: msg,
      imgSuccess: imgSuccess,
    });
  }

  hideErrorMSG(){
    this.alertmsg = false;
  }
}
