import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() optionItems = [];
  @Input() placeholder = '';
  @Input() bindValue = 'id';
  @Input() bindLabel = 'name';
  @Input() initValues = [];
  @Input() id = '';
  @Input() maxLength = 4;
  @Output() selectedOptions = new EventEmitter();
  @Output() onScrollValues = new EventEmitter();
  @Output() openSelectEmitter = new EventEmitter();
  multiSelectFormGroup: UntypedFormGroup;
  selectedValues: any[] = [];
  @ViewChild('allSelected') allSelected;
  selectAll: boolean;
  @Input() disableSelect: boolean = false;
  @Output() updateOptions = new EventEmitter();
  searchInput: string;
  timeout: any = null;
  @Input() dynamicServicesItems: any;
  @ViewChild('select') select: NgSelectComponent;
  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost =
        (event.target?.className as string)?.indexOf(
          'ng-dropdown-panel-items'
        ) > -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.select.close();
    }
  };

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.onChanges();
    window.addEventListener('scroll', this.onScroll, true);
  }
  
  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  openSelect(event) {
    this.openSelectEmitter.emit(true);
    this.optionItems = [...event];
  }

  createFormControls() {
    return this.multiSelectFormGroup.controls;
  }

  createForm() {
    this.multiSelectFormGroup = this.formBuilder.group({
      services: [null],
    });
  }

  onScrollPagination(items) {
    if (this.dynamicServicesItems) {
      this.onScrollValues.emit(true);
      this.dynamicServicesItems.subscribe((data) => {
        this.optionItems = data;
        if (data) {
          let result = data.reduce((arr, item) => {
            let exists = !!arr.find((x) => x.id === item.id);
            if (!exists) {
              arr.push(item);
            }
            return arr;
          }, []);
          this.openSelect(result);
        }
      });
    }
  }

  ngOnChanges(event) {
    setTimeout(() => {
      this.multiSelectFormGroup.patchValue({
        services: this.initValues,
      });
    });
  }

  onChanges(): void {
    this.multiSelectFormGroup.valueChanges.subscribe((val) => {
      this.selectedOptions.emit(
        this.multiSelectFormGroup.controls.services.value
      );
    });
  }

  onSelectionChange(selectedItems) {
    if (selectedItems.target && selectedItems.target.defaultValue == '0')
      return;
    else {
      if (selectedItems.length < this.optionItems?.length) {
        this.selectAll = false;
      } else if (selectedItems.length == this.optionItems?.length) {
        this.selectAll = true;
      }
    }
  }

  toggleCheckAll(values: any) {
    this.selectAll = true;
    if (values.currentTarget.checked) {
      this.selectAllItems();
      values.currentTarget.checked = true;
    } else {
      this.unselectAllItems();
    }
  }

  private selectAllItems() {
    this.multiSelectFormGroup.controls.services.patchValue([
      ...this.optionItems?.map((item) => item[this.bindValue]),
    ]);
    this.selectedValues = this.multiSelectFormGroup.controls.services.value;
  }

  unselectAllItems() {
    this.multiSelectFormGroup.controls.services.patchValue([]);
    this.selectedValues = [];
  }

  checkmark(item) {
    this.selectedValues = this.multiSelectFormGroup.controls.services.value;
    if (this.selectedValues.indexOf(item[this.bindValue]) > -1) {
      this.selectedValues.splice(
        this.selectedValues.indexOf(item[this.bindValue]),
        1
      );
    } else this.selectedValues.push(item[this.bindValue]);
    this.multiSelectFormGroup.controls.services.patchValue([
      ...this.selectedValues,
    ]);
  }

  changeOnSearch() {
    this.searchInput = this.select.searchTerm;
    clearTimeout(this.timeout);
    let that = this;
    that.timeout = setTimeout(() => {
      if (that.searchInput != '') {
        this.updateOptions.emit({
          searchInput: that.searchInput,
        });
      } else {
        this.updateOptions.emit({
          page: 1,
        });
      }
    }, 600);
  }

  resetSearch() {
    this.updateOptions.emit({
      reset: true,
    });
  }

  getNotActiveClass(item) {
    if ('is_active' in item && !item['is_active']) {
      return 'not-active'
    }
  }
}
