import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-location-categories',
  templateUrl: './location-categories.component.html',
  styleUrls: ['./location-categories.component.scss']
})
export class LocationCategoriesComponent {
  places_categotris: any;

  searchValue :string = ''

  constructor(private http :HttpClient) { }

  getCategories(){
    var requestOptions = {
      method: 'GET',
    };
    
    fetch("https://api.geoapify.com/v2/places?categories=commercial,catering,education,accommodation,entertainment,childcare,healthcare,leisure,natural,parking,pet,rental,service,tourism,public_transport,activity&filter=circle:"+this.searchValue+",5000&bias=proximity:"+this.searchValue+"&lang=ar&limit=500&apiKey=05ff8e7cd42d4e9f94ba16a1fed6c2ae", requestOptions)
      .then(response => response.json())
      .then(
        result => {
          const categoies = [];
          const categoriesMap = [];
          let features = result.features ;
          for(let feature of features){
            let title = feature.properties.categories[0];
            let sub_category = feature.properties.categories[1];
            let distance = ((feature.properties.distance) / 1000).toFixed(1);
            sub_category = sub_category?.slice(sub_category.indexOf('.')+1);
            if(categoies.indexOf(title) < 0 ){
              categoies.push(title);
              categoriesMap.push({name : title ,newCategories :[] , sub_categories : [sub_category , distance]});
            }
            else{
              categoriesMap.forEach(item =>{
                if(item.name == title){
                  item.sub_categories.push(sub_category);
                  item.sub_categories.push(distance);
                }
              });
            }
          }
          this.setCounts(categoriesMap);
        }
        )
      .catch(error => console.log('error', error));
  }

  setCounts(categories){
    categories.forEach((category) => {
      category.sub_categories.forEach((item , index) => {
        if(index % 2 === 0)
          category[item] = (category[item] || 0) + 1;
      });
      let keys = Object.keys(category);
      keys.forEach((key) =>{
        if(key != 'name' && key != 'newCategories' && key != 'sub_categories' && key !='distance')
          category.newCategories.push({name : key , count : category[key]})
      })
    });
    this.places_categotris = categories;
    this.getSubDistance();   
  }

  getSubDistance(){
    this.places_categotris.forEach((category , i) => {
      category.newCategories.forEach((newElem , j) => {
        let index = this.places_categotris[i].sub_categories.indexOf(newElem.name)+1;
        while(this.places_categotris[i].sub_categories[index] == 'NaN'){
          index += 2;
        }
        newElem["distance"]= this.places_categotris[i].sub_categories[index];
      });
    });
    console.log(this.places_categotris)
  }

  deleteSpace(){
    this.searchValue = this.searchValue.replace(/\s/g, '');
  }

  back(){
    this.places_categotris = undefined;
  }
}
