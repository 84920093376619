import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminOrdersService } from 'src/app/features/orders/services/orders/admin-orders.service';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';

@Component({
  selector: 'order-status-menu',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, NgSelectModule, MatMenuModule],
  templateUrl: './order-status-menu.component.html',
  styleUrl: './order-status-menu.component.scss',
})
export class OrderStatusMenuComponent implements OnInit {
  @Input() selectedRowsPK: number[];
  @Output() changedOrder = new EventEmitter();

  orderStatusObj: { id: OrderStatus; name: string }[];
  actionStatus: number;
  orderStatus = OrderStatus;
  constructor(private orderService: AdminOrdersService){}

  ngOnInit(): void {
    this.orderStatusObj =  [
      { id: this.orderStatus.Under_Inspectoring, name: 'قيد الفحص' },
      { id: this.orderStatus.Under_Audit, name: 'قيد التدقيق' },
      { id: this.orderStatus.Delivered, name: 'تم التسليم' },
      { id: this.orderStatus.Completed, name: ' مكتمل' },
    ];
  }

  changeOrderStatus(actionId:number) {

   this.actionStatus = actionId;
   this.orderService.changeOrdersStatus(this.selectedRowsPK, actionId).subscribe(response =>{
    if(response?.is_updated) {
    this.changedOrder.emit({done: true});
    }
    else {
      this.changedOrder.emit({done: false});
    }
   },(error) => {
    this.changedOrder.emit({done: false, error: error.error.error});
   });
  }
}
