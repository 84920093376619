@if (spinner) {
<app-spinner></app-spinner>
}
<div>
  <div class="inner-page-header">
    <h3>العملاء</h3>
  </div>
  @if (rowData) {
  <div class="request-control">
    <div class="right-side"> 
      <div class="delete-icon-grid">
        <button
          class="btn btn-custom-delete"
          (click)="goProfile()"
          [disabled]="disabledDelete"
        >
          <img src="assets/images/svg/account.svg" alt="" />
        </button>
      </div>
      <delete-selection
        [disabledDelete]="disabledDelete"
        [id]="customerId"
        [index]="deleteCustomerIndex"
        [deleteMessage]="deleteMsg"
        (deleteResult)="delete($event)"
      >
      </delete-selection>
      @if (filterData) {
      <app-filter
        [filterData]="filterData"
        [filterDataInit]="filterDataInit"
        [filterPage]="filterPage"
        (formValus)="filterFormValus($event)"
      >
      </app-filter>
      }
      <quick-search (searchResults)="quickSearch($event)"></quick-search>
    </div>
    <div class="new-element">
      <div class="instruction">
        <notification-menu
          [disableAction]="disableAction"
          [selectedRows]="selectedRows"
          [query]="searchValue"
        ></notification-menu>
      </div>
      <button class="btn btn-add" (click)="addClient()">
        <img src="assets/images/svg/user-icon.svg" alt="" />
        مستخدم جديد
      </button>
    </div>
  </div>
  }
  <div class="saved-filters">
    <saved-filters></saved-filters>
  </div>
  <div class="grid-section">
    <ag-grid-angular
      #agGrid
      style="width: 100%; height: 100%"
      id="myGrid"
      enableRtl="true"
      class="ag-theme-alpine"
      [rowData]="rowData | paginate : config"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [components]="frameworkComponents"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      [paginationPageSize]="paginationPageSize"
      (rowSelected)="onRowSelected($event)"
      [rowSelection]="rowSelection"
    >
    </ag-grid-angular>
  </div>
</div>

@if (clients && clients.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
>
</ayen-pagination>
}
