import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/_helpers/httpconfig.interceptor';
import {
  HttpClientJsonpModule,
  provideHttpClient,
  withJsonpSupport,
} from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';
import { FeaturesModule } from './features/features.module';
import { HeaderComponent } from './layout/components/header/header.component';
import { SidebarComponent } from './layout/components/sidebar/sidebar.component';
import { MainPageComponent } from './layout/main-page/main-page.component';
import { MatMenuModule } from '@angular/material/menu';
import { LocationCategoriesComponent } from './location-categories/location-categories.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    LocationCategoriesComponent,
    HeaderComponent,
    SidebarComponent,
    MainPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    CoreModule,
    FeaturesModule,
    SharedModule,
    AuthModule,
    BrowserAnimationsModule,
    FormsModule,
    MatMenuModule,
  ],
  providers: [
    provideHttpClient(withJsonpSupport()),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
