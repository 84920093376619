import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getLogs(body) {
    return this.http.post(this.baseURL + `/admin/data-dog-logs/`, body);
  }

}
