import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { OrdersComponent } from './orders.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { OrderReportComponent } from './components/order-report/order-report.component';
import { OrderInspectingLogComponent } from './components/order-inspecting-log/order-inspecting-log.component';
import { OrderReportsLogComponent } from './components/order-reports-log/order-reports-log.component';
import { OrdersListComponent } from './pages/orders-list/orders-list.component';
import { SearchFilterPipe } from 'src/app/core/_helpers/pipe/search-filter.pipe';
import { NewOrderComponent } from './pages/new-order/new-order.component';
import { StepperComponent } from './components/new-order/stepper/stepper.component';
import { BuildDetailsComponent } from './components/new-order/build-details/build-details.component';
import { InspectionDetailsComponent } from './components/new-order/inspection-details/inspection-details.component';
import { OrderSummaryComponent } from './components/new-order/order-summary/order-summary.component';
import { ClientNotesComponent } from './components/client-notes/client-notes.component';
import { OrderMainDetailsComponent } from './components/order-main-details/order-main-details.component';
import { OrderFilesComponent } from './components/order-files/order-files.component';
import { RefundViewComponent } from './components/refund-view/refund-view.component';
import { MainInfoComponent } from './components/order-info/components/main-info/main-info.component';
import { InspectorInfoComponent } from './components/order-info/components/inspector-info/inspector-info.component';
import { FinancialInfoComponent } from './components/order-info/components/financial-info/financial-info.component';
import { AdditionalAmountComponent } from './pages/additional-amount/additional-amount.component';
import { AmountDetailsComponent } from './components/additional-amount/amount-details/amount-details.component';
import { AmountSummaryComponent } from './components/additional-amount/amount-summary/amount-summary.component';
import { AdditionalAmountViewComponent } from './components/additional-amount/additional-amount-view/additional-amount-view.component';
import { RatingViewComponent } from './components/rating-view/rating-view.component';
import { ServicesOrdersListComponent } from './pages/services-orders-list/services-orders-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SharedModule } from 'src/app/shared/shared.module';
import { BuildingDetailsComponent } from './components/building-info/building-details/building-details.component';
import { BuildingSpecificationsComponent } from './components/building-info/building-specifications/building-specifications.component';
import { OtherFeaturesComponent } from './components/building-info/other-features/other-features.component';
import { PropertyLocationComponent } from './components/building-info/property-location/property-location.component';
import { InfrastructureComponent } from './components/infrastructure/infrastructure.component';
import { NotesRecommendationsComponent } from './components/notes-recommendations/notes-recommendations.component';
import { QcSurveyComponent } from './components/qc-survey/qc-survey.component';
import { QuatationModalComponent } from './components/quatation-modal/quatation-modal.component';
import { RefundModalComponent } from './components/refund-modal/refund-modal.component';
import { ServiceOrderMainDetailsComponent } from './components/services-orders/service-order-main-details/service-order-main-details.component';
import { ServiceFinancialInfoComponent } from './components/services-orders/services-order-info/components/service-financial-info/service-financial-info.component';
import { ServiceMainInfoComponent } from './components/services-orders/services-order-info/components/service-main-info/service-main-info.component';
import { ServiceOffersInfoComponent } from './components/services-orders/services-order-info/components/service-offers-info/service-offers-info.component';
import { ServiceProviderInfoComponent } from './components/services-orders/services-order-info/components/service-provider-info/service-provider-info.component';
import { ServiceQuatationInfoComponent } from './components/services-orders/services-order-info/components/service-quatation-info/service-quatation-info.component';
import { ServicesOrderInfoComponent } from './components/services-orders/services-order-info/services-order-info.component';
import { VisitSummaryComponent } from './components/visit-summary/visit-summary.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { RefundComponent } from './pages/refund/refund.component';
import { ServiceOrderDetailsComponent } from './pages/service-order-details/service-order-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderState } from './state/order.state';
import { NgxsModule } from '@ngxs/store';
import { AyenMapModule } from 'ayen-google-map';
import { OrderAdditionsComponent } from './components/order-additions/order-additions.component';
import { OrderStatusMenuComponent } from 'src/app/shared/components/order-status-menu/order-status-menu.component';
class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return [
      'الأحد',
      'الإثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
    ];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    OrdersComponent,
    OrderDetailsComponent,
    OrderInfoComponent,
    OrderReportComponent,
    OrderInspectingLogComponent,
    OrderReportsLogComponent,
    OrdersListComponent,
    SearchFilterPipe,
    NewOrderComponent,
    StepperComponent,
    BuildDetailsComponent,
    InspectionDetailsComponent,
    OrderSummaryComponent,
    ClientNotesComponent,
    OrderMainDetailsComponent,
    OrderFilesComponent,
    RefundViewComponent,
    MainInfoComponent,
    InspectorInfoComponent,
    FinancialInfoComponent,
    AdditionalAmountComponent,
    AmountDetailsComponent,
    AmountSummaryComponent,
    AdditionalAmountViewComponent,
    RatingViewComponent,
    ServicesOrdersListComponent,
    ServiceOrderDetailsComponent,
    ServicesOrderInfoComponent,
    ServiceMainInfoComponent,
    ServiceProviderInfoComponent,
    ServiceFinancialInfoComponent,
    ServiceQuatationInfoComponent,
    ServiceOffersInfoComponent,
    ServiceOrderMainDetailsComponent,
    BuildingSpecificationsComponent,
    BuildingDetailsComponent,
    OtherFeaturesComponent,
    PropertyLocationComponent,
    NotesRecommendationsComponent,
    InfrastructureComponent,
    VisitSummaryComponent,
    RefundComponent,
    PurchaseComponent,
    RefundModalComponent,
    QcSurveyComponent,
    QuatationModalComponent,
    OrderAdditionsComponent
  ],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    AyenMapModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    MatTableModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxSummernoteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    NgxsModule.forFeature([OrderState]),
    OrderStatusMenuComponent
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-eg' },
  ],
})
export class OrdersModule {}
