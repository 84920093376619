import { Component, OnInit } from '@angular/core';
import { LogsService } from './services/logs/logs.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss',
})
export class LogsComponent implements OnInit {

  logs: any[] = [];
  nextPage: string = '';
  
  constructor(private logService: LogsService) {}

  ngOnInit(): void {
    this.getLogs(this.nextPage);
    
  }

  getLogs(params: any) {
    this.logService.getLogs(params).subscribe(result => {
      this.logs.push(...result['data']);
      this.nextPage = result['links']['next'];
    })
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
     if(this.nextPage) {
      setTimeout(() => {
        this.getLogs({'next': this.nextPage});
      }, 500);
     }
    }
  }

  formatJsonToTable(payload) {
    if(this.isParsableJSON(payload) || typeof payload === 'object') {
      const json = typeof payload === 'object' ? payload : JSON.parse(payload);
      if(Object.keys(json).length > 0 ) {
        let formatted = "<div style='direction: ltr;text-align: justify;'>";
        for (const [key, value] of Object.entries(json)) {
          if (value && typeof value === "object" && !Array.isArray(value)) {
            formatted += `<p><strong>${key}:</strong> ${this.formatJsonToTable(value)}</p>`;
          } else if (Array.isArray(value)) {
            formatted += `<p><strong>${key}:</strong> [${value.join(", ")}]</p>`;
          } else {
            formatted += `<p><strong>${key}:</strong> ${value}</p>`;
          }
        }
        formatted += "</div>";
        return formatted;
      }
    } else {
      return payload;
    }
  }

  isParsableJSON(jsonString: string): boolean {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }
}
