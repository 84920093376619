<div class="orders-managment">
  <button class="btn btn-green" mat-button [matMenuTriggerFor]="orderMenu">
    إدارة الطلبات
    <img src="assets/images/svg/dropDownArrow.svg" alt="">
  </button>
  <mat-menu #orderMenu="matMenu">
    <a (click)="requestReinspection()" mat-menu-item>
      <img src="assets/images/svg/order-reinspction.svg" alt="">
      إنشاء طلبات إعادة فحص
    </a>
    <a mat-menu-item (click)="addOrderToProject()">
      <img src="assets/images/svg/add-order-project.svg" alt="">
      إضافة الطلبات لمشروع
    </a>
    <a mat-menu-item (click)="addInspector()">
      <img src="assets/images/svg/assign-inspector.svg" alt="">
      تعيين فاحص للطلبات
    </a>
    <a mat-menu-item (click)="reassignInspector()">
      <img src="assets/images/svg/assign-inspector.svg" alt="">
     إعادة تعيين فاحص للطلبات 
    </a>
    <a mat-menu-item (click)="linkOrder()">
      <img src="assets/images/svg/link-icon.svg" class="link-icon" alt="">
      ربط الطلبات
    </a>
    <a mat-menu-item (click)="unLinkOrder()">
      <img src="assets/images/svg/link-icon.svg" class="link-icon" alt="">
      فك ربط الطلبات
    </a>
    <a mat-menu-item (click)="changePaymentInspectorsStatus()">
      <img src="assets/images/svg/assign-inspector.svg" alt="">
       تم الدفع 
    </a>
  </mat-menu>
</div>
