<div class="inner-page-header">
  <h3>
    إعدادت الخدمة /
    <span class="subtitle" (click)="goService()">{{
      serviceDetails?.name
    }}</span>
  </h3>
</div>
<div class="new-service-wrapper">
  <form [formGroup]="serviceSettingsFormGroup">
    <div class="main-card">
      <div class="setting-wrapper">
        <div class="flex-wrapper">
          <div class="form-group">
            <app-switch-input
              [label]="' عدم إمكانية الفحص'"
              [labelEn]="'allow_inspector_to_skip_terms'"
              [index]="1"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['allow_inspector_to_skip_terms'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'عرض البنود بالقائمة المنسدلة'"
              [labelEn]="'is_droplist'"
              [index]="2"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['is_droplist'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل اجمالي السعر المخالفات'"
              [labelEn]="'show_violation_total_price_field'"
              [index]="3"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_violation_total_price_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل كمية المخالفات'"
              [labelEn]="'show_violation_count_field'"
              [index]="4"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_violation_count_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل البند'"
              [labelEn]="'show_term_field'"
              [index]="6"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_term_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل المرجعية'"
              [labelEn]="'show_description_field'"
              [index]="7"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_description_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل الصور'"
              [labelEn]="'show_photos_field'"
              [index]="8"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_photos_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل الحالة'"
              [labelEn]="'show_status_field'"
              [index]="5"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_status_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل الملاحظات'"
              [labelEn]="'show_notes_field'"
              [index]="20"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_notes_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل الغرف'"
              [labelEn]="'show_room_field'"
              [index]="9"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_room_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل الادوار'"
              [labelEn]="'show_floor_field'"
              [index]="10"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_floor_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل وحدة القياس'"
              [labelEn]="'show_units_of_measurement_field'"
              [index]="11"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_units_of_measurement_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل  الكمية'"
              [labelEn]="'show_quantity_field'"
              [index]="15"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_quantity_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group">
            <app-switch-input
              [label]="'اظهار حقل التوصية'"
              [labelEn]="'show_recommendation_field'"
              [index]="14"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['show_recommendation_field'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
          <div class="form-group col-md-6">
            <app-switch-input
              [label]="'اظهار زر الذكاء الإصطناعى'"
              [labelEn]="'is_ai_enabled'"
              [index]="16"
              [labelDesc]="'(مفعل/غير مفعل)'"
              [value]="serviceSettingsFormGroup.controls['is_ai_enabled'].value"
              (valueChanged)="isActiveValueChanged($event)"
            >
            </app-switch-input>
          </div>
        </div>
      </div>

      <div class="border-line"></div>

      <div class="flex-wrapper">
        <div class="form-group">
          <label for="" class="form-label">بيانات إضافية للتقرير</label>
          <app-select
            [optionItems]="allowanceFields"
            [placeholder]="'يرجى تحديد بيانات التقرير'"
            [id]="'allowanceFields'"
            [initValues]="initAllowanceFields"
            [maxLength]="1"
            (selectedOptions)="setAllowanceFields($event)"
          >
          </app-select>
        </div>
        @if(additionList.length > 0) {
        <div class="form-group">
          <label for="" class="form-label"> إضافات الخدمة </label>
          <app-select
            [optionItems]="additionList"
            [placeholder]="'يرجى تحديد الإضافات'"
            [id]="'additions'"
            [initValues]="initAdditions"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [maxLength]="2"
            [dynamicServicesItems]="dynamicServicesItems"
            (selectedOptions)="setDropMenus($event, 'additions')"
            (onScrollValues)="
              onScrollOptions($event, 'addition', 'getDynamicAdditions')
            "
          >
          </app-select>
        </div>
        }
        <div class="form-group">
          <label for="" class="form-label">
             إضافة وحدات القياس 
            <img
             (click)="edit({label:'تعديل الوحدة', list: this.unitList, fnName: 'updateUnitOfMeasurements' }, 'getUnitMesurment')"
             alt=""
             src="/assets/images/svg/edit-icon.svg"
            />
          </label>
          <a class="add-item ms-10" (click)="addUnitMeasurment()"
            ><img alt="" src="/assets/images/svg/add-icon-blue.svg" /> إضافة
            وحدة 
          </a>
          <app-select
            [optionItems]="unitList"
            [placeholder]="'يرجى تحديد وحدات القياس'"
            [id]="'units'"
            [initValues]="initUnits"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [maxLength]="2"
            [dynamicServicesItems]="unitItem"
            (selectedOptions)="setDropMenus($event, 'units_of_measurement')"
            (onScrollValues)="
              onScrollOptions($event, 'units', 'getUnitMesurment')
            "
          >
          </app-select>
        </div>
        <div class="form-group">
          <label for="" class="form-label">
            طريقة اظهار المشكلات في التقرير
          </label>
          <ng-select
            bindLabel="name"
            class="single-select"
            placeholder="طريقة اظهار المشكلات في التقرير "
            appendTo="body"
            formControlName="show_technical_problems_type"
            [searchable]="true"
            [clearable]="true"
          >
            @for (type of problemsList; track type) {
            <ng-option [value]="type.id">
              {{ type.name }}
            </ng-option>
            }
          </ng-select>
        </div>
        @if(serviceSettingsFormGroup.controls['show_room_field'].value) {
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label for="" class="form-label">
              الغرف
              <img
                (click)="edit({label:'تعديل الغرف', list: this.roomsList, fnName: 'updateRoom' }, 'getRooms')"
                alt=""
                src="/assets/images/svg/edit-icon.svg"
              />
            </label>
            <a class="add-item ms-10" (click)="addRoom()"
              ><img alt="" src="/assets/images/svg/add-icon-blue.svg" /> إضافة
              غرفة
            </a>
          </div>

          <app-select
            [optionItems]="roomsList"
            [placeholder]="'يرجى تحديد الغرف'"
            [id]="'room'"
            [initValues]="initRooms"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [dynamicServicesItems]="roomsItems"
            [maxLength]="2"
            (selectedOptions)="setDropMenus($event, 'rooms')"
            (onScrollValues)="onScrollOptions($event, 'rooms', 'getRooms')"
          >
          </app-select>
        </div>
        } @if(serviceSettingsFormGroup.controls['show_floor_field'].value) {
        <div class="form-group">
          <label for="" class="form-label">
            إضافة الادوار
            <img
              (click)="edit({label:'تعديل الأدوار', list: this.floorsList, fnName: 'updateFloor' }, 'getFloors')"
              alt=""
              src="/assets/images/svg/edit-icon.svg"
            />
          </label>
          <a class="add-item" (click)="addFloors()"
            ><img alt="" src="/assets/images/svg/add-icon-blue.svg" /> إضافة دور
          </a>
          <app-select
            [optionItems]="floorsList"
            [placeholder]="'يرجى تحديد الادوار'"
            [id]="'floors'"
            [initValues]="initFloors"
            [dynamicServicesItems]="floorsItems"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [maxLength]="2"
            (selectedOptions)="setDropMenus($event, 'floors')"
            (onScrollValues)="onScrollOptions($event, 'floors', 'getFloors')"
          >
          </app-select>
        </div>
        }
        @if(serviceSettingsFormGroup.controls['show_recommendation_field'].value)
        {
        <div class="form-group">
          <label for="" class="form-label"> التوصيات 
            <img
            (click)="editRecommendation()"
            alt=""
            src="/assets/images/svg/edit-icon.svg"
          />
          </label>
          <a class="add-item" (click)="addRecommendation()">
            <img alt="" src="/assets/images/svg/add-icon-blue.svg" /> إضافة
            توصية
          </a>
          <app-select
            [optionItems]="recommendationsList"
            [placeholder]="'يرجى تحديد التوصيات'"
            [id]="'recommendation'"
            [initValues]="initRecommendations"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [dynamicServicesItems]="recommendationItems"
            [maxLength]="2"
            (selectedOptions)="setDropMenus($event, 'recommendations')"
            (onScrollValues)="
              onScrollOptions($event, 'recommendations', 'getRecommendations')
            "
          >
          </app-select>
        </div>
        }
      </div>
      <div class="single-action">
        <button class="btn btn-green" (click)="save()">حفظ</button>
      </div>

      <!-- @if (alertmsg) {
      <div class="alert-box">
        <img
          src="assets/images/svg/error-icon.svg"
          class="close-alert"
          (click)="closeError()"
          alt=""
        />
        <p class="alert-msg">{{ message }}</p>
      </div>
      } -->
    </div>
  </form>
</div>
