<div class="new-element">
  <button class="btn btn-add" mat-button [matMenuTriggerFor]="additionMenu">
    <img src="assets/images/svg/add-icon.svg" alt="">
     إضافة
  </button>
  <mat-menu #additionMenu="matMenu">
    <a (click)="newOrder()" mat-menu-item>
      إنشاء طلب جديد  
    </a>
    <a mat-menu-item (click)="amount()">
      إنشاء خدمة إضافية 
    </a>
  </mat-menu>
</div>
