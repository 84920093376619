<div class="row">
  <div class="col-3 basic-data">
    <h4> رقم الطلب</h4>
    <p>
      #{{orders?.order_details?.reference_number}}
      @if (is_test) {
        <span class="test-label">تجريبي</span>
      }
      @if (orders.parent_id) {
        <span class="test-label parent" (click)="openOrder(orders.parent_id)">الطلب
        الرئيسي</span>
      }
    </p>
  </div>
  <div class="col-3 basic-data">
    <h4>اسم العميل</h4>
    <div class="double-tooltip">
      <p> {{orders?.order_details?.order_client_object?.name}}
        <span class="custom-tooltip">
          <img src="assets/images/svg/phone-tooltip.svg" alt="" />
          <span class="tooltip-text">{{orders?.client_mobile}}</span>
        </span>
        <span class="custom-tooltip">
          <img src="assets/images/svg/email-tooltip.svg" alt="" />
          <span class="tooltip-text">{{orders.client_email}}</span>
        </span>
      </p>

    </div>
  </div>
  <div class="col-3 basic-data">
    <h4> تاريخ الإنشاء</h4>
    <p> {{orders?.order_details?.created_at? orders?.order_details?.created_at : '-'}} </p>
  </div>
  <div class="col-3 basic-data">
    <h4> تاريخ الإغلاق </h4>
    <p>{{orders?.closed_at? orders?.closed_at : '-'}} </p>
  </div>

</div>
<div class="row">
  <div class="col-3 basic-data">
    <h4> تاريخ الاعتماد </h4>
    <p>{{orders?.approved_at || '-'}} </p>
  </div>
  <div class="col-3 basic-data">
    <h4>  تم الاعتماد من قبل  </h4>
    <p>{{orders?.approved_by?.name || '-'}} </p>
  </div>
  <div class="col-3 basic-data">
    <h4> حالة الطلب </h4>
    <p> {{orders?.order_details?.order_status_object?.name? orders?.order_details?.order_status_object?.name : '-'}}
    </p>
  </div>
  <div class="col-3 basic-data">
    <h4> الخدمة</h4>
    <p> {{orders?.order_details?.order_type_object?.name? orders?.order_details?.order_type_object?.name : '-'}} </p>
  </div>


</div>
<div class="row">
  <div class="col-3 basic-data">
    <h4> نوع العقار </h4>
    <p> {{orders?.building_type_object?.name?orders?.building_type_object?.name : '-'}} </p>
  </div>
  <div class="col-3 basic-data">
    <h4> مدينة العقار </h4>
    <p> {{orders?.order_details?.google_address_city? orders?.order_details?.google_address_city: '-' }} </p>
  </div>
  @if (orders.order_type != 5) {
    <div class="col-3 basic-data">
      <h4> إجمالى مساحة الوحدة</h4>
      <p> {{orders?.building_total_space_details.total_space}} <span>م<sup>2</sup></span></p>
    </div>
  }
  @if (orders.order_type != 5) {
    <div class="col-3 basic-data">
      <h4>مساحة الوحدة الأساسية</h4>
      <p> {{orders?.building_total_space_details.basic_space}} <span>م<sup>2</sup></span></p>
    </div>
  }
</div>
<div class="row">
  @if (orders?.client_requested_inspector) {
    <div class="col-3 basic-data">
      <h4> الفاحص المعيين من قبل العميل </h4>
      <p> {{orders?.client_requested_inspector.full_name}}</p>
    </div>
  }
  @if (orders.order_type != 5) {
    <div class="col-3 basic-data">
      <h4>مساحة الوحدة الإضافية</h4>
      <p> {{orders?.building_total_space_details.additional_space}} <span>م<sup>2</sup></span></p>
    </div>
  }
  <div class="col-3 basic-data">
    <h4> العنوان </h4>
    <p>{{address}}</p>
    <p class="address-map" (click)="copyAddress()">نسخ العنوان على الخريطة</p>
  </div>
  @if(orders?.re_inspection_order_version) {
  <div class="col-3 basic-data">
    <h4> رقم النسخة</h4>
    <p>نسخة {{orders?.re_inspection_order_version}}</p>
  </div>
  }
  @if (orders.order_type != 5 && !is_test) {
    <div class="col-3 basic-data">
      <h4> طلب تجريبي </h4>
      <app-switch-input [label]="''" [labelEn]="'is_test'" #switchInputTest [index]="'testBtn'" [value]="is_test"
        (valueChanged)="codeValueChanged($event, switchInputTest)">
      </app-switch-input>
    </div>
  }
  <div class="col-3 basic-data">
    <h4>اتاحة الطلب للفاحصين</h4>
    <app-switch-input [label]="''" [labelEn]="'allowed_in_order_bool'"  #switchInputBool [index]="'poolBtn'" [value]="allowed_in_order_bool"
      (valueChanged)="codeValueChanged($event, switchInputBool)">
    </app-switch-input>
  </div>

  <div class="col-3">
    <button class="btn btn-copy" (click)="copyOrderLink()" >
      <img src="assets/images/svg/copy-report-link.svg" alt=""/>
      <span>نسخ رابط الدفع</span>
    </button>
  </div>
</div>
