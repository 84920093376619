<div class="existing-report" >
  <div class="report-actions">
    @if (fixedReport) {
      <button class="btn btn-custom-delete" (click)="acceptReport()">
        <img src="assets/images/svg/accept_report.svg" alt=""/>
        <span>قبول</span>
      </button>
    }
    @if (fixedReport) {
      <button class="btn btn-custom-delete" (click)="editReport()" >
        <img src="assets/images/svg/reject_report.svg" alt=""/>
        <span> اعتراض</span>
      </button>
    }
    <button class="btn btn-custom-delete" (click)="reviewReport('view')" >
      <img src="assets/images/svg/preview_report.svg" alt=""/>
      <span>معاينة </span>
    </button>
    <button class="btn btn-custom-delete" (click)="previewAsDemo()" >
      <img src="assets/images/svg/preview_report.svg" alt=""/>
      <span>معاينة تجريبي</span>
    </button>
    <button class="btn btn-custom-delete custom-img" (click)="downloadReport()" >
      @if (!download && !orderData.report_file) {
        <img src="assets/images/svg/download_report.svg" alt=""/>
      }
      @if(orderData.report_file) {
      <img src="assets/images/svg/preview_report.svg" alt=""/>
      }
      @if (download) {
        <img class="spin-img" src="assets/images/svg/spinner-download.svg" alt=""/>
      }
      <span> {{downloadLabel}} </span>
    </button>
    <button class="btn btn-custom-delete custom-img" (click)="downloadAlbumReport()" >
      @if (!downloadAlbum  && !orderData.gallery_report_file) {
        <img src="assets/images/svg/download_report.svg" alt=""/>
      }
      @if(orderData.gallery_report_file) {
        <img src="assets/images/svg/preview_report.svg" alt=""/>
      }
      @if (downloadAlbum) {
        <img class="spin-img" src="assets/images/svg/spinner-download.svg" alt=""/>
      }
      <span>  {{downloadGallaryLabel}} </span>
    </button>
    <button class="btn btn-custom-delete custom-img" (click)="downloadAsExcel()" >
      @if (!downloadExcel) {
        <img src="assets/images/svg/download_report.svg" alt=""/>
      }
      @if (downloadExcel) {
        <img class="spin-img" src="assets/images/svg/spinner-download.svg" alt=""/>
      }
      <span> تحميل التقرير اكسيل </span>
    </button>
    @if (orderData.report_link) {
      <button class="btn btn-custom-delete" (click)="copyReportLink()" >
        <img src="assets/images/svg/copy-report-link.svg" alt=""/>
        <span>نسخ الرابط</span>
      </button>
    }
  </div>
  <div class="report-viewed">
    <app-switch-input
      [label]="'إتاحة وصول العميل للتقرير'"
      [labelEn] = "'is_report_accessible'"
      [value]="orderData.is_report_accessible"
      #switchInputReport
      (valueChanged) = "reportViewStatus($event, switchInputReport)">
    </app-switch-input>
  </div>
</div>
