import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { RefundService } from '../../services/refund/refund.service';
import { RefundModalComponent } from '../refund-modal/refund-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OrderState } from '../../state/order.state';

@Component({
  selector: 'app-refund-view',
  templateUrl: './refund-view.component.html',
  styleUrls: ['./refund-view.component.scss'],
})
export class RefundViewComponent implements OnInit {
  refundData: any = [];
  modelImg: boolean;
  confirmMsg: string;
  paid = {
    is_paid: true,
  };
  orderStatus = OrderStatus;
  orderType = OrderType;
  refundDialogRef: MatDialogRef<RefundModalComponent>;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  orderData;

  constructor(
    private refundService: RefundService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orderData = data;
      this.getOrderCostRefund();
    });
  }

  getOrderCostRefund() {
    this.refundService.getOrderCostRefund(this.orderData.id).subscribe(
      (data) => {
        this.refundData = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }

  deliveredOrder(id, paid) {
    this.refundService.changeRefundStatus(id, paid).subscribe(
      (data) => {
        this.refundData[0].status = 'مسترد';
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  applyNewRefund(data) {
    this.getOrderCostRefund();
  }

  openRefundModal() {
    this.refundDialogRef = this.dialog.open(RefundModalComponent, {
      data: {
        final_cost: this.orderData.final_cost,
        orderID: this.orderData.id,
      },
    });
    this.refundDialogRef.afterClosed().subscribe((result) => {
      if (result?.type) {
        this.refundData.push(result);
      }
    });
  }
}
