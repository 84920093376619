
<div class="inner-page-header">
  <h3>السجلات</h3>
</div>
<div class="request-control"></div>
<div class="request-table">
  <div class="table-responsive" (scroll)="onScroll($event)">
    <table id="logs-table" aria-label="" class="table table-striped" >
      <thead>
        <tr>
          <th>user_id</th>
          <th>user_name</th>
          <th>user_role</th>
          <th >date</th>
          <th > ip_address</th>
          <th>method </th>
          <th >request_url</th>
          <th> time </th>
          <th class="width-20"> payload </th>
          <th >user_agent </th>
        </tr>
      </thead>
      <tbody>
        @for ( log of logs; track log; let i = $index) {
        <tr>
          <td >
            {{log.user_id}}
          </td>
          <td >
            {{ log.user_name}}
          </td>
          <td >
            {{ log.user_role }}
          </td>
          <td >
            {{ log.date }}
          </td>
          <td >{{ log.ip_address }}</td>
          <td >
            {{ log.method}}
          </td>
          <td >
            {{ log.request_url }}
          </td>
          <td >
            {{ log.time}}
          </td>
          <td class="align-left" [innerHTML]="formatJsonToTable(log.payload_data)"></td>
          <td >
            {{ log.user_agent}}
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
