import { UserType } from "src/app/models/enums/user-type.enum";

export class RedirectionUrls {
  
  public static redirectRoot = {
    [UserType.Assistant]: { url: 'pages/orders' },
    [UserType.Supervisor]: { url: 'pages/home' },
    [UserType.Admin]: { url: 'pages/home' },
    [UserType.Markting]: { url: 'pages/markting/send-sms' },
    [UserType.OrderDataEntry]: { url: 'pages/projects/orders' },
    [UserType.AssignmentOffice]: { url: 'pages/projects/orders' },
    [UserType.InspectorActivationOffice]: { url: 'pages/inspectors/inspectors-grid' },
    [UserType.SupervisorQC]: { url: 'pages/projects/orders' },
    [UserType.QC]: { url: 'pages/projects/orders' },
  };
}
