<div class="orders-status orders-managment">
  <button class="btn btn-add" mat-button [matMenuTriggerFor]="orderStatusMenu" [disabled]="selectedRowsPK.length === 0">
    تغير حالة الطلبات
    <img src="assets/images/svg/dropDownArrow.svg" alt="" />
  </button>
  <mat-menu #orderStatusMenu="matMenu" backdropClass="action-menu">
    @for(item of orderStatusObj; track item.id){
    <a mat-menu-item (click)="changeOrderStatus(item.id)">
      {{ item.name }}
    </a>
    }
  </mat-menu>
</div>
